<template>
  <div class="personal-center-block">
    <img class="banner" src="@/assets/images/banner2.png" />
    <div class="width-1200 flex">
      <div class="tabs-block">
        <div
          :class="{
            'tabs-item': true,
            active: _activeName == 'activities'
          }"
          @click="activeNameChange('activities')"
        >
          <span>我的参会</span>
          <img class="right-arrow" src="@/assets/images/arrow_more.png" />
        </div>
        <div
          :class="{
            'tabs-item': true,
            active: _activeName == 'purchased_data'
          }"
          @click="activeNameChange('purchased_data')"
        >
          <span>我的课程</span>
          <img class="right-arrow" src="@/assets/images/arrow_more.png" />
        </div>

        <div class="my-order">
          <div class="title">我的订单</div>

          <div
            :class="{
              'tabs-item': true,
              active: _activeName == 'order_activity'
            }"
            @click="activeNameChange('order_activity')"
          >
            <span>活动订单</span>
            <img class="right-arrow" src="@/assets/images/arrow_more.png" />
          </div>

          <div
            :class="{
              'tabs-item': true,
              active: _activeName == 'order_course'
            }"
            @click="activeNameChange('order_course')"
          >
            <span>课程订单</span>
            <img class="right-arrow" src="@/assets/images/arrow_more.png" />
          </div>
        </div>
        <el-icon><CaretRight /></el-icon>
      </div>

      <div class="card-block background-ffffff">
        <div class="title">我的课程</div>
        <!-- 我的参会 -->
        <PurchasedDataActivity v-if="_activeName == 'activities'" />

        <!-- 我的课程、已购买课程、免费课程、vip课程 -->
        <PurchasedDataCourse v-if="_activeName == 'purchased_data'" />

        <!-- 活动订单 -->
        <OrderActivity v-if="_activeName == 'order_activity'" />

        <!-- 课程购买订单 -->
        <OrderCourse v-if="_activeName == 'order_course'" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import * as _request from '@/api/user.js'
import router from '@/router/index.js'
import PurchasedDataActivity from './components/purchased_data_activity.vue'
import PurchasedDataCourse from './components/purchased_data_course.vue'
import OrderActivity from './components/order_activity.vue'
import OrderCourse from './components/order_course.vue'

/**
 * @description tab切换
 */
const _activeName = ref('activities')
_activeName.value = router.currentRoute.value.query.activeName
  ? router.currentRoute.value.query.activeName
  : _activeName.value

function activeNameChange(_val) {
  _activeName.value = _val
  router.push({
    path: '/personal-center',
    query: {
      activeName: _val
    }
  })
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.personal-center-block {
  background: #f4f4f4;

  .banner {
    width: 100%;
  }

  .width-1200.flex {
    justify-content: space-between;
  }

  .tabs-block {
    background: #ffffff;
    border-radius: 6px;
    margin-top: 40px;
    position: relative;
    padding: 20px 20px 6px 20px;
    box-sizing: border-box;
    // height: 154px;
    height: fit-content;

    .tabs-item {
      width: 180px;
      height: 50px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      margin-bottom: 14px;
      cursor: pointer;

      .right-arrow {
        display: none;
      }

      &:hover {
        background: #f2f8fc;
        border: 1px solid #e0e0e0;
        font-weight: bold;
        color: #323232;

        .right-arrow {
          display: block;
        }
      }

      &.active {
        background: #f2f8fc;
        border: 1px solid #e0e0e0;
        font-weight: bold;
        color: #323232;

        .right-arrow {
          display: block;
        }
      }
    }

    .el-icon {
      color: #ffffff;
      position: absolute;
      right: -14px;
      top: 30px;
      font-size: 23px;
    }

    .my-order {
      width: 180px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      margin-bottom: 14px;

      .title {
        width: 180px;
        height: 50px;
        background: #f2f8fc;
        border-radius: 4px 4px 0px 0px;
        font-size: 14px;
        font-weight: bold;
        color: #323232;
        line-height: 50px;
        box-sizing: border-box;
        padding: 0 20px;
      }

      .tabs-item {
        width: 148px;
        height: 32px;
        background: #f5f5f5;
        border-radius: 16px;
        border-color: #ffffff;
        margin: 16px auto;

        &.active,
        &:hover {
          color: #0e6eb8;
        }
      }
    }
  }

  .card-block {
    width: 950px;
    background: #ffffff;
    padding: 40px 30px 80px 30px;
    box-sizing: border-box;
    border-radius: 0;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 0 0 30px 0;
      display: flex;
      align-items: center;

      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 20px;
        background: #0e6eb8;
        border-radius: 2px;
        margin-right: 12px;
      }
    }
  }
}
</style>
