<template>
  <div class="order-course-block">
    <el-tabs v-model="_activeName" @tab-change="handleClick">
      <el-tab-pane label="全部" name="all">
        <!-- 订单列表 -->
        <OrderList
          v-if="_activeName == 'all'"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
      <el-tab-pane label="待审核" name="audit">
        <!-- 订单列表 -->
        <OrderList
          v-if="_activeName == 'audit'"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
      <el-tab-pane label="已驳回" name="reject">
        <!-- 订单列表 -->
        <OrderList
          v-if="_activeName == 'reject'"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
      <el-tab-pane label="已完成" name="finish">
        <!-- 订单列表 -->
        <OrderList
          v-if="_activeName == 'finish'"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
      <el-tab-pane label="已废弃" name="scrap">
        <!-- 订单列表 -->
        <OrderList
          v-if="_activeName == 'scrap'"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
    </el-tabs>

    <div class="pagination-block">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :total="pageTotal"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, provide } from 'vue'
import * as _request from '@/api/user.js'
import router from '@/router/index.js'
import OrderList from './order_course_list.vue'

/**
 * @description tabs选项卡
 * 订单状态,1，待审核；2，驳回；3，已完成；4，废弃
 */
const _activeName = ref('all')
const handleClick = (tab, event) => {
  console.log('_activeName', _activeName.value)
  if (_activeName.value == 'all') {
    getOrderList()
  } else if (_activeName.value == 'audit') {
    getOrderList(1)
  } else if (_activeName.value == 'reject') {
    getOrderList(2)
  } else if (_activeName.value == 'finish') {
    getOrderList(3)
  } else if (_activeName.value == 'scrap') {
    getOrderList(4)
  }
}

/**
 * @description 订单列表刷新
 */
provide('orderCourseListRefresh', handleClick)

/**
 * @description 分页数据获取
 */
const _orderList = ref([])
const currentPage = ref(1)
const pageSize = ref(9)
const pageTotal = ref(0)
getOrderList()
function getOrderList(_status) {
  const _params = {
    page: currentPage.value,
    limit: pageSize.value,
    status: _status || ''
  }
  _request.ajax_course_order(_params).then((res) => {
    // console.log('res', res)
    pageTotal.value = res.data.count || 0
    _orderList.value = res.data.list || []
  })
}

/**
 * @description 分页切换
 * @param {*} val
 */
 const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
  currentPage.value = val
  if (_activeName.value == 'all') {
    getOrderList()
  } else if (_activeName.value == 'audit') {
    getOrderList(1)
  } else if (_activeName.value == 'reject') {
    getOrderList(2)
  } else if (_activeName.value == 'finish') {
    getOrderList(3)
  } else if (_activeName.value == 'scrap') {
    getOrderList(4)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.order-course-block {
  :deep(.el-tabs) {
    .el-tabs__nav {
      margin-left: 40px;
    }

    .el-tabs__item {
      padding: 0 35px;
    }
  }

  .pagination-block {
    border-top: 1px solid #e6e6e6;
    padding-top: 30px;
  }
}
</style>
