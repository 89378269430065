<template>
  <el-dialog
    v-model="dialogVisible"
    title="重置密码"
    width="500px"
    class="dialog-block"
    :before-close="handleClose"
  >
    <div class="register-form-block">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        :size="formSize"
        status-icon
      >
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email" clearable />
        </el-form-item>
        <!-- <el-form-item label="邮箱" prop="email">
          <div class="flex email">
            <el-input v-model="ruleForm.email" clearable />
            <div class="btn">验证码</div>
          </div>
        </el-form-item>
        <el-form-item label="验证码" prop="username">
          <el-input v-model="ruleForm.username" clearable />
          <p>请从邮箱获取验证码</p>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            show-password
            clearable
          />
          <p>最低应有8位字符组成</p>
        </el-form-item> -->
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="login-submit"
          type="primary"
          @click="submitForm(ruleFormRef)"
        >
          确 定
        </el-button>
      </span>
      <div class="forget-register flex">
        已有账户，立即
        <span class="register" @click="toLogin">登录</span>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, inject, nextTick } from 'vue'
import * as _request from '@/api/login.js'
import { ElMessage } from 'element-plus'

const dialogVisible = ref(true)
const loginDialogVisibleChange = inject('loginDialogVisibleChange')
const forgetPasswordDialogVisibleChange = inject(
  'forgetPasswordDialogVisibleChange'
)

/**
 * @description 表单
 */
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = reactive({
  email: '',
  // password: '',
  // username: '',
  // mobile: '',
})

// //验证密码是否合法
// const password = (rule, value, callback) => {
//   if (value == '') {
//     return callback(new Error('密码不能为空'))
//     // callback();
//   } else if (value.length >= 8) {
//     callback()
//   } else {
//     callback(new Error('最低应由8位字符组成'))
//   }
// }

// //验证密码是否合法，且与密码相同
// const confirmPassword = (rule, value, callback) => {
//   if (value == '') {
//     return callback(new Error('密码不能为空'))
//     // callback();
//   } else if (value.length >= 8 && value == ruleForm.newPassword) {
//     callback()
//   } else {
//     callback(new Error('密码和确认密码应保持一致'))
//   }
// }

const rules = reactive({
  email: [
    { required: true, message: '请输入邮箱账户', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  // password: [
  //   { required: true, validator: password, trigger: 'blur' }
  //   // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
  // ],
  // confirmPassword: [
  //   { required: true, validator: confirmPassword, trigger: 'blur' }
  //   // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
  // ],
})

/**
 * @description 提交
 */
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      const _params = {
        email: ruleForm.email,
      }
      _request.ajax_find_pass(_params).then((res) => {
        // console.log('res', res)
        if (res.error_code == 0) {
          ElMessage({
            message: '操作成功，请前往邮箱获取重置后密码！',
            type: 'success',
            duration: 1000
          })
          forgetPasswordDialogVisibleChange()
          nextTick(() => {
            loginDialogVisibleChange()
          }, 100)
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

const handleClose = (done) => {
  forgetPasswordDialogVisibleChange()
}

/**
 * @description 登录
 */
function toLogin() {
  forgetPasswordDialogVisibleChange()
  nextTick(() => {
    loginDialogVisibleChange()
  }, 100)
}
</script>
<style lang="scss" scoped>
.dialog-block {
  .register-form-block {
    box-sizing: border-box;
    /* padding: 40px 40px 0 40px; */

    p {
      margin: 5px 0 0 0;
      line-height: 1;
      color: #999999;
      font-size: 12px;
    }

    .email.flex {
      justify-content: space-between;
      align-items: center;
      .el-input {
        flex-grow: 1;
        width: 260px;
      }

      .btn {
        width: 80px;
        height: 38px;
        background: #0e6eb8;
        color: #ffffff;
        text-align: center;
        line-height: 36px;
        flex-shrink: 0;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
          background: #0099ff;
        }
      }
    }
  }

  .forget-register {
    box-sizing: border-box;
    padding: 0 20px 10px 20px;
    margin-top: 18px;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: bold;
    color: #656565;
    line-height: 1;
  }

  .forget,
  .register {
    cursor: pointer;
  }

  .register {
    color: #0e6eb8;
  }
}
</style>