<template>
  <el-dialog
    v-model="dialogVisible"
    title="注册"
    width="500px"
    class="dialog-block"
    :before-close="handleClose"
  >
    <div class="register-form-block">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        :size="formSize"
        status-icon
      >
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email" clearable />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            show-password
            clearable
          />
          <p>最低应有8位字符组成</p>
        </el-form-item>
        <el-form-item label="姓名" prop="username">
          <el-input v-model="ruleForm.username" clearable />
          <p>请填写真实姓名</p>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="ruleForm.mobile" clearable />
          <p>请填写方便与您联系的电话号码</p>
        </el-form-item>
        <!-- <el-form-item label="单位名称" prop="unit_name">
          <el-input v-model="ruleForm.unit_name" clearable />
          <p>请准确填写您的单位名称</p>
        </el-form-item>
        <el-form-item label="单位税号">
          <el-input v-model="ruleForm.unit_num" clearable />
          <p>请准确填写您的单位税号</p>
        </el-form-item> -->
        <el-form-item label="" prop="agree">
          <el-checkbox v-model="ruleForm.agree" label="确认勾选" />
          <span class="register" @click="toFuWuTiaoKuan">《服务条款》</span>
          和
          <span class="register" @click="toYinSiTiaoKuan">《隐私条款》</span>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          :loading="_btnLoading"
          class="login-submit"
          type="primary"
          @click="submitForm(ruleFormRef)"
        >
          注 册
        </el-button>
      </span>
      <div class="forget-register flex">
        已有账户，立即
        <span class="register" @click="toLogin">登录</span>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, inject, nextTick } from 'vue'
import * as _request from '@/api/login.js'
import { ElMessage } from 'element-plus'

const dialogVisible = ref(true)
const loginDialogVisibleChange = inject('loginDialogVisibleChange')
const registerDialogVisibleChange = inject('registerDialogVisibleChange')
const FuWuTiaoKuanDialogVisibleChange = inject(
  'FuWuTiaoKuanDialogVisibleChange'
)
const YinSiTiaoKuanDialogVisibleChange = inject(
  'YinSiTiaoKuanDialogVisibleChange'
)

/**
 * @description 表单
 */
const _btnLoading = ref(false)
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = reactive({
  email: '',
  password: '',
  username: '',
  mobile: '',
  unit_name: '', // 单位名称
  unit_num: '', // 单位税号
  agree: '' // 同意协议
})

//验证密码是否合法
const password = (rule, value, callback) => {
  if (value == '') {
    return callback(new Error('密码不能为空'))
    // callback();
  } else if (value.length >= 8) {
    callback()
  } else {
    callback(new Error('最低应由8位字符组成'))
  }
}

// //验证密码是否合法，且与密码相同
// const confirmPassword = (rule, value, callback) => {
//   if (value == '') {
//     return callback(new Error('密码不能为空'))
//     // callback();
//   } else if (value.length >= 8 && value == ruleForm.newPassword) {
//     callback()
//   } else {
//     callback(new Error('密码和确认密码应保持一致'))
//   }
// }

//验证手机号是否合法
const validateTel = (rule, value, callback) => {
  if (value == '') {
    callback(new Error('手机号不能为空'))
  } else {
    const telReg =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
    if (telReg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入正确手机号'))
    }
  }
}

const rules = reactive({
  email: [
    { required: true, message: '请输入邮箱账户', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  password: [
    { required: true, validator: password, trigger: 'blur' }
    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
  ],
  username: [
    { required: true, message: '请输入真实姓名', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  mobile: [
    {
      required: true,
      validator: validateTel,
      trigger: 'blur'
    }
    // { required: true, message: '请输入手机号', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  unit_name: [
    { required: true, message: '请输入公司名称', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  agree: [
    {
      required: true,
      message: '请确认并勾选《服务条款》和《隐私条款》',
      trigger: 'change'
    }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ]
  // confirmPassword: [
  //   { required: true, validator: confirmPassword, trigger: 'blur' }
  //   // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
  // ],
})

/**
 * @description 登录
 */
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      _btnLoading.value = true
      const _params = {
        email: ruleForm.email,
        password: ruleForm.password,
        username: ruleForm.username,
        mobile: ruleForm.mobile,
        unit_name: ruleForm.unit_name,
        unit_num: ruleForm.unit_num
      }
      _request
        .register(_params)
        .then((res) => {
          // console.log('res', res)
          _btnLoading.value = false
          if (res.error_code == 0) {
            ElMessage({
              message: '注册成功，请先前往注册邮箱激活账号！',
              type: 'success',
              duration: 3000
            })
            registerDialogVisibleChange()
            nextTick(() => {
              loginDialogVisibleChange()
            }, 100)
          }
        })
        .finally((err) => {
          _btnLoading.value = false
          // console.log('catch: ', err)
        })
    } else {
      console.log('error submit!', fields)
    }
  })
}

const handleClose = (done) => {
  registerDialogVisibleChange()
}

/**
 * @description 查看服务条款
 */
function toFuWuTiaoKuan() {
  FuWuTiaoKuanDialogVisibleChange()
}

/**
 * @description 查看隐私条款
 */
function toYinSiTiaoKuan() {
  YinSiTiaoKuanDialogVisibleChange()
}

/**
 * @description 登录
 */
function toLogin() {
  registerDialogVisibleChange()
  nextTick(() => {
    loginDialogVisibleChange()
  }, 100)
}
</script>
<style lang="scss" scoped>
.dialog-block {
  .register-form-block {
    box-sizing: border-box;
    /* padding: 40px 40px 0 40px; */

    p {
      margin: 5px 0 0 0;
      line-height: 1;
      color: #999999;
      font-size: 12px;
    }
  }

  .forget-register {
    box-sizing: border-box;
    padding: 0 20px 10px 20px;
    margin-top: 18px;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: bold;
    color: #656565;
    line-height: 1;
  }

  .forget,
  .register {
    cursor: pointer;
  }

  .register {
    color: #0e6eb8;
  }
}
</style>