<template>
  <el-dialog
    v-model="dialogVisible"
    title="登录"
    width="440px"
    class="dialog-block"
    :before-close="handleClose"
  >
    <div class="login-form-block">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="55px"
        :size="formSize"
        status-icon
      >
        <el-form-item label="邮箱" prop="login_string">
          <el-input v-model="ruleForm.login_string" clearable />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            show-password
            clearable
          />
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="ruleForm.is_remember" label="记住登录信息" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="login-submit"
          type="primary"
          @click="submitForm(ruleFormRef)"
        >
          登 录
        </el-button>
      </span>
      <div class="forget-register flex">
        <span class="forget" @click="toForget">忘记密码</span>
        <span class="register" @click="toRegister">注册账号</span>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, inject, nextTick } from 'vue'
import * as _request from '@/api/login.js'
import { ElMessage } from 'element-plus'

const dialogVisible = ref(true)
// const loginDialogVisible = inject('loginDialogVisible')
const loginDialogVisibleChange = inject('loginDialogVisibleChange')
const registerDialogVisibleChange = inject('registerDialogVisibleChange')
const forgetPasswordDialogVisibleChange = inject('forgetPasswordDialogVisibleChange')
const userInfoChange = inject('userInfoChange')

/**
 * @description 表单
 */
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = reactive({
  login_string: '',
  password: '',
  is_remember: ''
})

const rules = reactive({
  login_string: [
    { required: true, message: '请输入邮箱账户', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ]
})

/**
 * @description 登录
 */
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      const _params = {
        login_string: ruleForm.login_string,
        password: ruleForm.password,
        is_remember: ruleForm.is_remember ? 1 : 0
      }
      _request.login(_params).then((res) => {
        // console.log('res', res)
        if (res.error_code == 0) {
          if (ruleForm.is_remember) {
            localStorage.setItem('loginInfo', JSON.stringify(_params))
          } else {
            localStorage.removeItem('loginInfo')
          }
          ElMessage({
            message: '登录成功',
            type: 'success',
            duration: 1000
          })
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          userInfoChange(res.data)
          loginDialogVisibleChange()
          setTimeout(() => {
            location.reload()
          }, 300)
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

const handleClose = (done) => {
  loginDialogVisibleChange()
}

/**
 * @description 从缓存中取一下缓存的账号密码，有的话直接赋值给表单
 */
getLoaclInfo()
function getLoaclInfo() {
  const _loginInfo = localStorage.getItem('loginInfo')
    ? JSON.parse(localStorage.getItem('loginInfo'))
    : {}
  ruleForm.login_string = _loginInfo.login_string
  ruleForm.password = _loginInfo.password
  //   const ruleForm = reactive({
  //   login_string: '',
  //   password: '',
  //   is_remember: ''
  // })
}

/**
 * @description 忘记密码
 */
function toForget() {
  forgetPasswordDialogVisibleChange()
  loginDialogVisibleChange()
  // nextTick(() => {
  //   registerDialogVisibleChange()
  // }, 100)
}

/**
 * @description 注册账号
 */
function toRegister() {
  loginDialogVisibleChange()
  nextTick(() => {
    registerDialogVisibleChange()
  }, 100)
}
</script>
<style lang="scss" scoped>
.dialog-block {
  .login-form-block {
    box-sizing: border-box;
    /* padding: 40px 40px 0 40px; */
  }

  .forget-register {
    box-sizing: border-box;
    padding: 0 20px 10px 20px;
    margin-top: 18px;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  .forget,
  .register {
    cursor: pointer;
  }

  .register {
    color: #0e6eb8;
  }
}
</style>