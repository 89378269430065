<template>
  <div class="card-list-block">
    <el-row :gutter="20">
      <el-col
        v-for="item in _propsData.data"
        :key="item.id"
        :span="_propsData.colSpanCount"
      >
        <div class="card-block" @click="toDetail(item)">
          <div class="margin-16">
            <img
              class="thumbnail"
              :src="item.act_focus_url ? item.act_focus_url : _defaultImg"
            />
            <h5>
              {{
                item.activity_name && item.activity_name.length > 15
                  ? item.activity_name.slice(0, 15) + '...'
                  : item.activity_name
              }}
            </h5>
            <p
              style="
                height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              时间：
              {{ item.activity_date }}
              <!-- {{
                item.activity_date && item.activity_date.length > 12
                  ? item.activity_date.slice(0, 12) + '...'
                  : item.activity_date
              }} -->
            </p>
            <p style="height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;">
              地点：
              {{ item.activity_area }}
              <!-- {{
                item.activity_area && item.activity_area.length > 12
                  ? item.activity_area.slice(0, 12) + '...'
                  : item.activity_area
              }} -->
            </p>
            <!-- <p style="height: 48px">
              {{
                item.activity_remarks && item.activity_remarks.length > 35
                  ? item.activity_remarks.slice(0, 35) + '...'
                  : item.activity_remarks
              }}
            </p> -->

            <div class="split-line"></div>

            <div class="flex purchase">
              <div class="flex-shrink0">
                <p>价格（元）</p>
                <!-- <p v-if="item.price_info.length == 0">
                  <span class="price">0</span>
                </p> -->
                <!-- <p v-for="priceItem in item.price_info" :key="priceItem.level">
                  <span class="price" v-if="priceItem.level == 2">{{
                    priceItem.price
                  }}</span>
                </p> -->
                <p>
                  <span class="price">{{
                    item.price_info && item.price_info.length > 0
                      ? item.price_info[0].price
                      : 0
                  }}</span>
                </p>
              </div>
              <!-- <div
                v-if="!item.is_take && item.price_info.length == 0"
                class="free-btn"
                @click="toPurchasedDataDetail(item)"
              >
                免费
              </div> -->
              <div class="flex btn-flex">
                <div
                  v-for="priceItem in item.price_info"
                  :key="priceItem.level"
                >
                  <!-- is_take = 1 当前活动有已完成购买的服务 -->
                  <div
                    v-if="!item.is_take && priceItem.level == 0"
                    class="free-btn"
                  >
                    <!-- @click="toPurchasedDataDetail(item)" -->
                    免费
                  </div>
                  <div v-if="priceItem.level == 2" class="purchase-btn">
                    <!-- @click="toCommodityDetails(item)" -->
                    预约报名
                  </div>
                  <div
                    v-if="!item.is_take && priceItem.level == 1"
                    class="vip-btn"
                  >
                    <!-- @click="toPurchasedDataDetail(item, 'vip')" -->
                    VIP专享
                  </div>
                </div>
              </div>
              <!-- <div
                class="free-btn"
                v-if="item.is_take"
                @click="toPurchasedDataDetail(item)"
              >
                查看
              </div> -->
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
// defineEmits
import { ref, inject, defineProps } from 'vue'
import * as _request from '@/api/login.js'
import router from '@/router/index.js'
import { ElMessageBox } from 'element-plus'
// import Cookies from 'js-cookie'

const _defaultImg = require('@/assets/images/banner.png') // 这里需要换成绝对路径
const userInfo = inject('userInfo')
const userInfoChange = inject('userInfoChange')
const loginDialogVisibleChange = inject('loginDialogVisibleChange')

/**
 * @description props参数
 */
const _propsData = defineProps({
  data: {
    type: Array,
    required: true,
    default: () => []
  },
  colSpanCount: {
    type: Number,
    default: 6
  }
})

/**
 * @description 用于判断卡片跳转哪个地址
 */
function toDetail(_item) {
  if (_item.price_info[0].level == 0) {
    toPurchasedDataDetail(_item)
  } else if (_item.price_info[0].level == 1) {
    toPurchasedDataDetail(_item, 'vip')
  } else if (_item.price_info[0].level == 2) {
    toCommodityDetails(_item)
  }
}

/**
 * @description 跳转商品详细信息
 */
function toCommodityDetails(_item) {
  if (!userInfo.value || !userInfo.value.email) {
    loginDialogVisibleChange()
    return
  }
  router.push({
    path: '/commodity-detail-activity',
    query: {
      id: _item.id,
      is_take: _item.is_take
    }
  })
}

/**
 * @description 跳转已购课程详情、目录页面
 */
function toPurchasedDataDetail(_item, _type) {
  if (!userInfo.value || !userInfo.value.email) {
    loginDialogVisibleChange()
    return
  }
  if (_type == 'vip' && userInfo.value.role <= 1) {
    ElMessageBox.alert(
      '您还不是VIP客户，在我站参加一次付费活动或购买一次付费课程后，自动成为VIP客户，也可联系客服单独购买。',
      '提示',
      {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: '知道了',
        callback: (action) => {
          // ElMessage({
          //   type: 'info',
          //   message: `action: ${action}`,
          // })
        }
      }
    )
  } else {
    router.push({
      path: '/purchased-data-detail-activity',
      query: {
        id: _item.id
      }
    })
  }
}

/**
 * @description 个人信息获取
 */
// getUserInfo()
// function getUserInfo() {
//   const _params = {}
//   _request.ajax_user_info(_params).then((res) => {
//     localStorage.setItem('userInfo', JSON.stringify(res.data))
//     userInfoChange(res.data)
//   })
// }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-list-block {
  .card-block {
    width: 100%;
    height: 380px;
    background: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;

    .thumbnail {
      width: 100%;
      height: 180px;
      background: #ffffff;
    }

    h5 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 1;
      margin: 20px 0 12px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      margin: 0;
    }

    .split-line {
      margin: 10px 0 0 0;
      height: 1px;
      width: 100%;
      background-color: #cccccc;
    }

    .purchase {
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 0 0;

      p {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 1;
        margin: 8px 0;
      }

      .btn-flex {
        flex-grow: 1;
        flex-wrap: wrap;
        justify-content: flex-end;

        & > div {
          margin: 2px;
        }
      }

      .price {
        font-size: 18px;
        font-weight: 500;
        color: #ff6d39;
      }

      .purchase-btn {
        width: 80px;
        height: 36px;
        background: #0e6eb8;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #0099ff;
        }
      }

      .free-btn {
        width: 80px;
        height: 36px;
        background: #ffffff;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #0e6eb8;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background: #fafafa;
        }
      }

      .vip-btn {
        width: 80px;
        height: 36px;
        background: #ff6d39;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background: #ef8d6a;
        }
      }
    }
  }
}
</style>
