<template>
  <el-dialog
    v-model="dialogVisible"
    title="重要提醒"
    width="900px"
    top="5vh"
    class="dialog-block"
    :before-close="handleClose"
  >
    <div class="login-form-block">
      <h3>《光锥时空学习网服务条款》</h3>

      <p>
        注册用户在使用北京光锥时空科技有限公司所提供的服务之前，请务必审慎阅读、充分理解本协议的各项条款内容。用户在光锥时空服务网站www.shikongxuexi.com注册过程中勾选本协议即视为该用户已阅读并同意本协议的全部内容。
      </p>
      <p>
        鉴于光锥时空网站（甲方：北京光锥时空科技有限公司）为注册用户（乙方）提供技能培训、课程授课、学习资料等有偿服务，为保障双方权益，甲乙双方就相关有偿服务达成如下协议：
      </p>
      <h5>1、服务方式</h5>
      <p>
        1）乙方在甲方指定的网站www.shikongxuexi.com的相关板块按照相应流程报名有偿会议培训、购买有偿音视频/文稿等资料。乙方支付相应费用并按照操作说明完成报名、购买后，甲方在活动约定的各时间节点为乙方提供相应服务。
      </p>
      <p>
        2）乙方应及时关注甲方发送到乙方预留邮箱地址或手机上的通知内容，并及时进行相应操作，若因乙方未及时查看通知或未按通知要求进行操作所造成的服务周期延长或耽误服务等问题由乙方承担。
      </p>
      <h5>2、关于发票</h5>
      <p>
        1）
        乙方完成支付后，若需要发票，需与甲方客服联系提供正确的纳税人抬头、税号等信息，甲方将在收到支付后30个工作日为乙方提供增值税普通电子发票。同时甲方将协助乙方提供会议通知、邀请信等资料，供乙方报销使用。
      </p>
      <p>
        2）
        若由甲方造成的发票信息错误，甲方将无偿更换；若因乙方提供信息错误或乙方原因需要变更发票信息，甲方提供一次免费发票变更，第二次变更需支付发票金额的5%作为发票变更服务费。
      </p>
      <p>
        3）
        甲方仅在支付完成后2个月内提供发票开具和变更，超出时限范围原则上不提供补开发票和变更发票。
      </p>

      <h5>3、关于会议/培训报名</h5>
      <p>
        1）甲方将按照事先公布的日程为乙方提供授课内容，在不变更授课内容和授课学时的情况下，甲方有权在告知乙方的情况下，对日程进行微调。若乙方对甲方的日程变更不满意，可在授课前全额退款退出报名。甲方将根据授课老师意愿，决定是否实名授课。
      </p>
      <p>
        2）除按照日程完成授课外，甲方需为乙方提供不短于2年的授课视频无限次在线观看权限，同时为乙方提供相应的授课资料。授课资料和音视频版权归甲方和授课老师，乙方不得翻录、传播相应资料，违规传播者将被追究法律责任。
      </p>
      <p>
        3）乙方报名时限截止到活动开始当天，乙方全额支付报名费用标志着乙方正式完成报名。若因乙方个人原因申请退出报名，可在活动开始前7天联系甲方客服办理全额退款；活动开始前七天至活动正式开始前，乙方退出活动需支付20%退会费；活动开始后，乙方申请退出活动时可退未授课部分的比例金额（不足一天按一天收费），并加收500元退费违约金。
      </p>
      <h5>4、关于资料购买</h5>
      <p>
        1）
        乙方在甲方提供的网站按需购买相应音视频课程资料，甲方为乙方提供相应的资料费发票等报销材料。
      </p>
      <p>
        2）
        乙方完成购买后，将获得相应资料的在线观看权限，考虑到资料的特殊属性，支付完成获得观看权限后将不能退款。
      </p>
      <p>
        3）甲方提供的文档资料和音视频等版权归甲方和相应授课老师，乙方不得翻录、传播相应资料，违规传播者将被追究法律责任。
      </p>
      <h5>5、关于支付</h5>
      <p>
        1）乙方可通过支付宝、微信、对公转账等方式完成相应付款，乙方需咨询本单位财务规定后根据自身情况选择合适的支付方式，若乙方完成支付后需变更支付方式，甲方可协助退款，乙方重新支付，退款需缴纳3%的服务费，退款周期为14个工作日。
      </p>
      <p>
        2）若乙方需通过单位汇款方式支付，则以汇款到账作为报名成功的标志，若活动开始前汇款未到账，乙方需个人先垫付费用以便参加学习，汇款到账后14个工作日甲方将退款至乙方，若产生相应的支付手续费，须由乙方承担。
      </p>

      <h5>6、争议解决和适用法律</h5>
      <p>1） 本协议受中华人民共和国法律管辖并按中华人民共和国法律解释。</p>
      <p>2） 对本协议的任何修改须经双方协商同意后方为有效。</p>
      <p>
        3）
        执行本协议发生争议时，双方应首先通过友好协商的方式解决；若协商不成，任何一方可向当地仲裁委员会提起仲裁申请予以解决。仲裁不成的，可以向当地法院提起诉讼。
      </p>
      <h5>7、协议时间</h5>
      <p>
        本协议自乙方通过光锥时空服务网站www.shikongxuexi.com提交服务需求起正式生效，对于版权等约定永久有效。
      </p>

      <h4>
        乙方在www.shikongxuexi.com注册过程中勾选本协议即视为该用户同意本协议的全部内容。
      </h4>
      <h4>2023年6月1日起执行</h4>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="login-submit" type="primary" @click="confirm">
          确 认
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, inject } from 'vue'

const dialogVisible = ref(true)
const FuWuTiaoKuanDialogVisibleChange = inject(
  'FuWuTiaoKuanDialogVisibleChange'
)

const handleClose = (done) => {
  FuWuTiaoKuanDialogVisibleChange()
}

const confirm = () => {
  FuWuTiaoKuanDialogVisibleChange()
}
</script>
<style lang="scss" scoped>
.dialog-block {
  .login-form-block {
    box-sizing: border-box;
    /* padding: 40px 40px 0 40px; */

    h3 {
      text-align: center;
      margin-top: 0;
      font-size: 26px;
    }

    h5 {
      font-size: 16px;
    }

    h4 {
      font-size: 16px;
      text-align: center;
    }

    p {
      font-size: 16px;
      text-indent: 30px;
    }
  }

  .forget-register {
    box-sizing: border-box;
    padding: 0 20px 10px 20px;
    margin-top: 18px;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  .forget,
  .register {
    cursor: pointer;
  }

  .register {
    color: #0e6eb8;
  }
}
</style>