<template>
  <div class="data-list-block">
    <div
      v-for="item in _propsData.data"
      :key="item.id"
      class="flex data-row"
      @click="toView(item)"
    >
      <img v-if="_propsData.type == 'video'" src="@/assets/images/video.png" />
      <img
        v-else-if="_propsData.type == 'audio'"
        src="@/assets/images/audio.png"
      />
      <img v-else src="@/assets/images/document.png" />
      <span v-if="_propsData.type == 'video'" class="label">【视频】</span>
      <span v-else-if="_propsData.type == 'audio'" class="label">【音频】</span>
      <span v-else class="label">【文档】</span>
      <span>{{ item.title }}</span>
    </div>

    <!-- <div class="flex data-row" @click="toView">
      <img v-if="_propsData.type == 'video'" src="@/assets/images/video.png" />
      <img
        v-else-if="_propsData.type == 'audio'"
        src="@/assets/images/video.png"
      />
      <img v-else src="@/assets/images/video.png" />
      <span v-if="_propsData.type == 'video'" class="label">【视频】</span>
      <span v-else-if="_propsData.type == 'audio'" class="label">【音频】</span>
      <span v-else class="label">【文档】</span>
      <span>第六期-基于MaxEnt生态位模型的物种分布预测模与参数优化 拷贝</span>
    </div> -->
  </div>
</template>

<script setup>
// defineEmits
import { ref, onMounted, reactive, defineProps } from 'vue'
import * as _request from '@/api/user.js'
import router from '@/router/index.js'
import * as commonFunc from '@/util/common-func.js'
import { ElMessage } from 'element-plus'

/**
 * @description props参数
 */
const _propsData = defineProps({
  data: {
    type: Boolean,
    required: true
  },
  type: {
    type: String,
    default: 'video'
  },
  // 课程还是活动
  category: {
    type: String,
    required: true,
    default: 'activity' // activity 、course
  },
  // 课程id 或 活动id
  categoryId: {
    type: String,
    required: true,
  },
})

console.log('_propsData', _propsData.data)

/**
 * @description 播放查看资料
 */
function toView(_item) {
  console.log('_item', _item)
  if (!_item.url) {
    ElMessage({
      message: '请先购买或完成订单',
      type: 'error',
      duration: 1000
    })
    return
  }
  // 文件资料新窗口打开
  if (_propsData.type == 'document') {
    window.open(_item.url)
  } else {
    // 视频、音频跳转到播放页面
    router.push({
      path: '/player',
      query: {
        id: _item.id,
        type: _propsData.type,
        url: commonFunc.encrypt(_item.url), // url加密
        type_key: _propsData.category, // 活动或课程
        id_key: _propsData.categoryId, // 活动id或课程id
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.data-list-block {
  // width: 1200px;
  // background: #f5f5f5;
  // border-radius: 6px;
  // box-sizing: border-box;
  // padding: 40px 40px 30px 40px;

  .data-row {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 17px 30px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    color: #292929;
    margin-bottom: 10px;
    cursor: pointer;

    img {
      margin-right: 11px;
    }

    .label {
      color: #0e6eb8;
      margin-right: 2px;
    }

    &:hover {
      background-color: #ecf5ff;
      color: #409eff;
    }
  }
}
</style>
