<template>
  <div class="purchased-data-detail-block">
    <!-- <img class="banner" src="@/assets/images/banner2.png" /> -->

    <div class="width-1400 background-ffffff overflow-hidden">
      <div class="width-1200">
        <div class="width-1200 crumbs-block flex">
          <el-link href="/personal-center">我的课程</el-link>
          <el-icon><ArrowRight /></el-icon>
          <span>详情</span>
        </div>
        <div class="purchased-data-detail flex">
          <div class="thumbnail">
            <img
              :src="
                _dataInfo.act_focus_url ? _dataInfo.act_focus_url : _defaultImg
              "
            />
          </div>
          <div class="price">
            <h2>{{ _dataInfo.activity_name }}</h2>
            <div class="flex flex-align-items">
              <span class="label">可选服务：</span>
              <div class="service-select flex">
                <template
                  v-for="(item, index) in _dataInfo.price_info"
                  :key="index"
                >
                  <!-- have_file = 1 当前服务已经完成购买、或者免费、或者VIP且当前用户是vip，总之就是自己能看的 -->
                  <div
                    @click="serviceClick(item)"
                    v-if="item.have_file == 1"
                    :class="{
                      'service-item': true,
                      active: _activeServiceId == item.id
                    }"
                  >
                    {{ item.level_name }}
                    <img
                      class="icon-selected"
                      src="@/assets/images/selected.png"
                    />
                  </div>
                </template>
              </div>
            </div>
            <p>
              <span class="label">时间：</span>{{ _dataInfo.activity_date }}
            </p>
            <p>
              <span class="label">地点：</span>{{ _dataInfo.activity_area }}
            </p>
            <p>
              <span class="label">活动介绍：</span>
              <span>
                {{ _dataInfo.activity_remarks }}
              </span>
            </p>
          </div>
        </div>

        <div class="purchased-data-detail-tabs-block">
          <el-tabs v-model="_activeName">
            <el-tab-pane label="介绍" name="introduce">
              <Introduce
                v-if="_dataInfo.activity_id && _activeName == 'introduce'"
                :data="_dataInfo"
              />
            </el-tab-pane>
            <el-tab-pane label="全部" name="directory">
              <div class="directory-block">
                <DirectoryList
                  v-if="_activeName == 'directory' && _activeFileList"
                  :data="_activeFileList[1]"
                  type="document"
                  category="activity"
                  :categoryId="router.currentRoute.value.query.id"
                />
                <DirectoryList
                  v-if="_activeName == 'directory' && _activeFileList"
                  :data="_activeFileList[2]"
                  type="video"
                  category="activity"
                  :categoryId="router.currentRoute.value.query.id"
                />
                <DirectoryList
                  v-if="_activeName == 'directory' && _activeFileList"
                  :data="_activeFileList[3]"
                  type="audio"
                  category="activity"
                  :categoryId="router.currentRoute.value.query.id"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="视频" name="video">
              <div class="directory-block">
                <DirectoryList
                  v-if="_activeName == 'video' && _activeFileList"
                  :data="_activeFileList[2]"
                  type="video"
                  category="activity"
                  :categoryId="router.currentRoute.value.query.id"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="音频" name="audio">
              <div class="directory-block">
                <DirectoryList
                  v-if="_activeName == 'audio' && _activeFileList"
                  :data="_activeFileList[3]"
                  type="audio"
                  category="activity"
                  :categoryId="router.currentRoute.value.query.id"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="文档" name="document">
              <div class="directory-block">
                <DirectoryList
                  v-if="_activeName == 'document' && _activeFileList"
                  :data="_activeFileList[1]"
                  type="document"
                  category="activity"
                  :categoryId="router.currentRoute.value.query.id"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import * as _request from '@/api/activities.js'
import router from '@/router/index.js'
import DirectoryList from '@/components/DirectoryList.vue'
import Introduce from './components/introduce.vue'

const _defaultImg = require('@/assets/images/banner.png') // 这里需要换成绝对路径
const _activeName = ref('introduce')

/**
 * @description 已选中服务id，服务目前只能单选
 */
const _activeServiceId = ref('')
const _activeServicePrice = ref('')
const _activeFileList = ref([]) // 已选中服务下面的课程
function serviceClick(_item) {
  _activeServiceId.value = _item.id
  _activeServicePrice.value = _item.price
  _activeFileList.value = _item.file_list
}

/**
 * @description 信息获取
 */
const _dataInfo = ref({})
getDataInfo()
function getDataInfo() {
  const _params = {
    activity_id: router.currentRoute.value.query.id
  }
  _request.ajax_activity_info(_params).then((res) => {
    // console.log('res', res.data)
    // 默认选中第一个已购买或免费或vip服务
    if (res.data.price_info) {
      for (let i = 0; i < res.data.price_info.length; i++) {
        if (res.data.price_info[i].have_file == 1) {
          _activeServiceId.value = res.data.price_info[i].id
          _activeServicePrice.value = res.data.price_info[i].price
          _activeFileList.value = res.data.price_info[i].file_list

          break
        }
      }
    }
    _dataInfo.value = res.data
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.purchased-data-detail-block {
  background: #f4f4f4;

  .banner {
    width: 100%;
  }

  .width-1200 {
    margin-top: 60px;
    margin-bottom: 80px;
  }

  .crumbs-block {
    margin: 30px auto;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #666666;

    .el-icon {
      margin: 0 6px;
    }

    span {
      color: #cccccc;
    }
  }

  .purchased-data-detail {
    .thumbnail {
      width: 480px;
      height: 340px; // 360px;
      background: #e7f4fa;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 40px;
      flex: none;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .price {
      flex-grow: 1;

      h2 {
        font-size: 20px;
        font-weight: bold;
        color: #292929;
        line-height: 1;
        padding: 20px 0 25px 0;
        border-bottom: 1px solid #e6e6e6;
        margin: 0;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 1.6;
        margin: 17px 0;
        display: flex;
      }

      .label {
        display: inline-block;
        width: 80px;
        font-size: 14px;
        font-weight: 500;
        color: #7f7f7f;
        flex: none; /** 使项目不能被压缩或放大*/
      }

      .service-select {
        flex-wrap: wrap;
        .service-item {
          min-width: 80px;
          height: 36px;
          background: #ffffff;
          border: 1px solid #999999;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 36px;
          text-align: center;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          position: relative;
          padding: 0 10px;

          .icon-selected {
            display: none;
            position: absolute;
            bottom: 0;
            right: 0;
          }

          &:hover {
            border: 1px solid #0e6eb8;
            color: #0e6eb8;
          }

          &.active {
            border: 1px solid #0e6eb8;
            color: #0e6eb8;
            .icon-selected {
              display: block;
            }
          }
        }
      }
    }
  }

  .purchased-data-detail-tabs-block {
    margin-top: 45px;

    :deep(.el-tabs) {
      .el-tabs__nav {
        margin-left: 50px;
      }

      .el-tabs__item {
        padding: 0 35px;
        font-size: 18px;
      }
    }

    .directory-block {
      width: 1200px;
      background: #f5f5f5;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 40px 40px 30px 40px;
    }
  }
}
</style>
