<template>
  <div class="home-block">
    <el-carousel height="430px" v-if="_bannerList.length > 0">
      <el-carousel-item v-for="item in _bannerList" :key="item.course_id">
        <div
          @click="toDetail(item)"
          class="banner"
          :style="{ backgroundImage: 'url(' + item.banner_img + ')' }"
        ></div>
      </el-carousel-item>
    </el-carousel>

    <!-- 会议沙龙 -->
    <div class="conference-salon-block width-1200">
      <div class="flex">
        <div>
          <h3>CONFERENCE SALON</h3>
          <h2>会议沙龙</h2>
        </div>
        <div class="more flex" @click="toMore('activity')">
          了解更多<img src="@/assets/images/arrow_more.png" />
        </div>
      </div>

      <div class="tabs-blcok flex">
        <div class="tabs-item-block active">
          <span>线上会议</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
      </div>

      <!-- 卡片样式的商品列表 -->
      <CardListActivity :data="_activityList" />
    </div>

    <!-- 课程购买 -->
    <div class="material-purchase-block width-1200">
      <div class="flex">
        <div>
          <h3>MATERIAL PURCHASE</h3>
          <h2>课程购买</h2>
        </div>
        <div class="more flex" @click="toMore('course')">
          了解更多<img src="@/assets/images/arrow_more.png" />
        </div>
      </div>

      <div class="tabs-blcok flex">
        <div
          :class="{ 'tabs-item-block': true, active: _activeName == 'all' }"
          @click="tabChange('all', -1)"
        >
          <span>全部课程</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
        <div
          :class="{ 'tabs-item-block': true, active: _activeName == 'free' }"
          @click="tabChange('free', 0)"
        >
          <span>免费课程</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
        <div
          :class="{ 'tabs-item-block': true, active: _activeName == 'vip' }"
          @click="tabChange('vip', 1)"
        >
          <span>会员专享</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
        <div
          :class="{
            'tabs-item-block': true,
            active: _activeName == 'purchased'
          }"
          @click="tabChange('purchased', 2)"
        >
          <span>付费课程</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
      </div>

      <!-- 卡片样式的商品列表 -->
      <CardListCourse :data="_courseList" />
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import * as _request from '@/api/activities.js'
import router from '@/router/index.js'
import CardListActivity from '../activity/components/CardList.vue'
import CardListCourse from '../course/components/CardList.vue'
const userInfo = inject('userInfo')
const loginDialogVisibleChange = inject('loginDialogVisibleChange')


/**
 * @description 点击banner跳转
 */
function toDetail(_item) {
  if (_item.redirect_url) {
    window.open(_item.redirect_url)
    return
  }
  if (_item.activity_id != 0) {
    if (!userInfo.value || !userInfo.value.email) {
      loginDialogVisibleChange()
      return
    }
    router.push({
      path: '/commodity-detail-activity',
      query: {
        id: _item.activity_id
      }
    })
  } else if (_item.course_id != 0) {
    if (!userInfo.value || !userInfo.value.email) {
      loginDialogVisibleChange()
      return
    }
    router.push({
      path: '/commodity-detail-course',
      query: {
        id: _item.course_id
      }
    })
  }
}

/**
 * @description banner获取
 */
const _bannerList = ref([])
getBannerList()
function getBannerList() {
  const _params = {
    page: 1,
    limit: 8
  }
  _request.ajax_banner_list(_params).then((res) => {
    console.log('res', res.data)
    _bannerList.value = res.data
  })
}

/**
 * @description 活动分页数据获取
 */
const _activityList = ref([])
getActivityList()
function getActivityList() {
  const _params = {
    page: 1,
    limit: 8
  }
  _request.ajax_activity_list(_params).then((res) => {
    console.log('res', res.data)
    res.data.list.map((item) => {
      const _price_info = []
      const _price_info_level = []
      item.price_info.map((child, childIndex) => {
        if (!_price_info_level.includes(child.level)) {
          _price_info.push(child)
          _price_info_level.push(child.level)
        }
      })
      item.price_info = _price_info
    })
    _activityList.value = res.data.list
  })
}

/**
 * @description 分类数据切换
 */
const _activeName = ref('all')
const _activeLevel = ref(-1)
function tabChange(_val, _level) {
  _activeName.value = _val
  _activeLevel.value = _level
  getCourseList()
}

/**
 * @description 课程分页数据获取
 */
const _courseList = ref([])
getCourseList()
function getCourseList() {
  const _params = {
    page: 1,
    limit: 8,
    level: _activeLevel.value,
    is_home: 1 // 首页展示排序
  }
  _request.ajax_course_list(_params).then((res) => {
    console.log('res', res.data)
    _courseList.value = res.data.list
  })
}

/**
 * @description 了解更多
 */
function toMore(_type) {
  if (_type == 'activity') {
    router.push({
      path: '/activity',
      query: {}
    })
  } else {
    router.push({
      path: '/course',
      query: {}
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.home-block {
  .el-carousel {
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
      cursor: pointer;
    }
  }

  .conference-salon-block {
    margin-top: 40px;
  }

  .material-purchase-block {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .conference-salon-block,
  .material-purchase-block {
    & > .flex {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 39px;
    }

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #c6dbeb;
      line-height: 1;
      margin: 0;
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 16px 0 0 0;
    }

    .more {
      width: 120px;
      height: 40px;
      border: 2px solid #0e6eb8;
      border-radius: 20px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 40px;
      align-items: center;
      justify-content: center;

      img {
        margin: 0 0 0 9px;
      }
    }

    .tabs-blcok {
      border-bottom: 2px solid #e6e6e6;
      padding: 0 0 16px 0;
      justify-content: flex-start;

      .tabs-item-block {
        width: 160px;
        height: 40px;
        background: #f0f0f0;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #0e6eb8;
        line-height: 40px;
        text-align: center;
        position: relative;
        cursor: pointer;
        margin-right: 16px;

        &:hover {
          background: #0e6eb8;
          color: #ffffff;
        }

        .el-icon {
          position: absolute;
          bottom: -10px;
          left: 50%;
          margin-left: -8px;
          display: none;
        }

        &.active {
          background: #0e6eb8;
          color: #ffffff;

          .el-icon {
            display: block;
            color: #0e6eb8;
          }
        }
      }
    }
  }
}
</style>
