<template>
  <el-dialog
    v-model="dialogVisible"
    title="上传成功汇款凭证"
    width="1000px"
    class="dialog-block"
    top="5vh"
    :before-close="handleClose"
  >
    <div class="login-form-block form-block">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        :size="formSize"
        status-icon
      >
        <div class="form-card">
          <el-form-item label="付款凭证" prop="pay_img">
            <div class="flex upload-block">
              <!-- :limit="1" -->
              <!-- :on-exceed="handleExceed" -->
              <el-upload
                :before-upload="beforeAvatarUpload"
                :on-error="handleError"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :action="`${_uploadApi}/api/m/activity/upload_img`"
                list-type="picture-card"
                ref="imgUpload"
                v-model:file-list="_uploadFileList"
              >
                <!-- :headers="{
        // Authorization: "Bearer " + this.$store.getters.access_token,
                }" -->
                <!-- :auto-upload="false" -->
                <!-- action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" -->
                <el-icon><Plus /></el-icon>

                <template #file="{ file }">
                  <div>
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <el-icon><zoom-in /></el-icon>
                      </span>
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <el-icon><Delete /></el-icon>
                      </span>
                    </span>
                  </div>
                </template>
              </el-upload>

              <div class="important-notice flex">
                <img src="@/assets/images/icon_remind.png" />
                <div>
                  <p>
                    请上传jpg、png格式的付款凭证截图。上传的文件大小不能超20MB。上传成功后请检查缩略图是否正常显示，若不正常，请重新上传!
                  </p>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- 支付方式 -->
          <PaymentMethod style="width: 800px" />
        </div>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="login-submit"
          type="primary"
          @click="submitForm(ruleFormRef)"
          :loading="_btnLoading"
        >
          确 认
        </el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 图片查看 -->
  <el-dialog v-model="_imgViewDialogVisible">
    <img w-full :src="dialogImageUrl" alt="Preview Image" />
  </el-dialog>
</template>

<script setup>
import { ref, reactive, defineProps, inject, defineEmits } from 'vue'
import * as _request from '@/api/user.js'
import { ElMessage } from 'element-plus'
import PaymentMethod from '@/components/PaymentMethod'

const _emitData = defineEmits(['close'])
const orderActivityListRefresh = inject('orderActivityListRefresh')
const orderCourseListRefresh = inject('orderCourseListRefresh')

/**
 * @description props参数
 */
const _propsData = defineProps({
  type: {
    type: String,
    default: 'activity'
  },
  id: {
    type: String,
    default: ''
  }
})

const dialogVisible = ref(true)
const _uploadApi = process.env.VUE_APP_UPLOAD_API

/**
 * @description 表单
 */
const _uploadFileList = ref([]) // 付款凭证list
const _btnLoading = ref(false)
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = reactive({
  id: '',
  pay_img: ''
})

// 校验文件上传
const uploadFile = (rule, value, callback) => {
  if (_uploadFileList.value.length === 0) {
    callback(new Error('请上传文件'))
  } else {
    callback()
  }
}
const rules = reactive({
  pay_img: [
    {
      required: true,
      validator: uploadFile,
      trigger: 'blur'
    }
  ]
})

/**
 * @description 登录
 */
const submitForm = async (formEl) => {
  if (!formEl) return
  if (!_uploadFileList.value[0].response) {
    return
  }
  await formEl.validate((valid, fields) => {
    if (valid) {
      _btnLoading.value = true
      ruleForm.id = _propsData.id
      ruleForm.pay_img = _uploadFileList.value[0].response.data
      const _params = ruleForm
      let _requestApi = _request.ajax_activity_pay
      if (_propsData.type == 'course') {
        _requestApi = _request.ajax_course_pay
      }
      _requestApi(_params)
        .then((res) => {
          ElMessage({
            message: '上传成功',
            type: 'success',
            duration: 1000
          })
          _emitData('close')
          if (_propsData.type == 'course') {
            orderCourseListRefresh()
          } else {
            orderActivityListRefresh()
          }
          _btnLoading.value = false
        })
        .finally((err) => {
          _btnLoading.value = false
          console.log('catch: ', err)
        })
    } else {
      console.log('error submit!', fields)
    }
  })
}

/**
 * @description 关闭
 */
function handleClose() {
  _emitData('close')
}

/**
 * @description 图片上传
 */
const dialogImageUrl = ref('')
const _imgViewDialogVisible = ref(false)
const imgUpload = ref()

/**
 * @description 限制上传文件的大小
 */
const beforeAvatarUpload = (file) => {
  // if (file.type !== 'image/jpeg') {
  //   ElMessage.error('Avatar picture must be JPG format!')
  //   return false
  // }
  console.log('file', file)
  const isOverSize = file.size / 1024 / 1024 <= 20
  if (!isOverSize) {
    ElMessage({
      message: '上传的文件大小不能超过20MB',
      type: 'error',
      duration: 1000
    })
  }
  return isOverSize
}

/**
 * @description 上传失败的钩子函数
 */
const handleError = (err, file, fileList) => {
  console.log('err, file, fileList', err, file, fileList)
  ElMessage({
    message: err.error_msg,
    type: 'error',
    duration: 1000
  })
}

/**
 * @description 上传成功的钩子函数
 */
const handleSuccess = (response, file, fileList) => {
  // 只保留一张图片
  console.log('response, file, fileList', response, file, fileList)
  if (_uploadFileList.value.length > 1) {
    _uploadFileList.value.splice(0, 1)
  }
}

/**
 * @description 图片删除
 */
const handleRemove = (file) => {
  console.log(file)
  _uploadFileList.value = []
  imgUpload.value.clearFiles()
}

/**
 * @description 图片查看
 */
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url ? file.url : ''
  _imgViewDialogVisible.value = true
}
</script>
<style lang="scss" scoped>
.dialog-block {
  .login-form-block {
    box-sizing: border-box;
    /* padding: 40px 40px 0 40px; */
  }

  .forget-register {
    box-sizing: border-box;
    padding: 0 20px 10px 20px;
    margin-top: 18px;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  .forget,
  .register {
    cursor: pointer;
  }

  .register {
    color: #0e6eb8;
  }

  .form-block {
    background: #ffffff;
    border-radius: 10px;
    // padding: 40px 60px 60px 60px;
    box-sizing: border-box;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 0 0 30px 0;
      display: flex;
      align-items: center;

      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 20px;
        background: #0e6eb8;
        border-radius: 2px;
        margin-right: 12px;
      }
    }

    .form-card {
      width: 100%;
      background: #f2f8fc;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 20px;
    }

    h5 {
      font-size: 16px;
      font-weight: 500;
      color: #2a2a2a;
      line-height: 1;
      margin: 0 0 24px 0;
    }

    .inhibit-input-text {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      line-height: 32px;
      margin: 0;
    }

    .tip {
      font-size: 14px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 10px 0 0 0;
    }

    .el-input,
    .el-textarea {
      width: 620px;
    }

    .upload-block {
      flex-grow: 1;
      justify-content: space-between;

      .important-notice {
        width: 390px;
      }
    }
  }

  .important-notice {
    background: #fff5f5;
    border: 1px solid #ffd6d6;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 30px;
    margin-bottom: 40px;

    img {
      width: 18px;
      height: 18px;
      margin: 0 6px 0 0;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 14px;
      color: #666666;
      line-height: 18px;
      margin: 0;
    }
  }
}
</style>