/**
 * @description 这是公共方法的文件
 */
import CryptoJS from "crypto-js";

/**
 * @description 这是校验密码格式的公共方法
 * 密码中必须包含大小写字母、数字、特殊字符四种中的三种,并且密码长度在8～15位之间
 * @param {_isPass} 校验成功返回true， 失败返回false
 */
const verifyPassword = (_password) => {
  const _rule = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,15}$/;
  const _isPass = _rule.test(_password);
  return _isPass;
};

/**
 * @description 得到字符串含有某个字符的个数
 * @param {*} str 某字符串
 * @param {*} char 某字符
 */
const getCharCount = (str, char) => {
  const regex = new RegExp(char, "g"); // 使用g表示整个字符串都要匹配
  const result = str.match(regex); //match方法可在字符串内检索指定的值，或找到一个或多个正则表达式的匹配。
  const count = !result ? 0 : result.length;
  return count;
};

const key = CryptoJS.enc.Utf8.parse("1234567890000000"); //16位
const iv = CryptoJS.enc.Utf8.parse("1234567890000000");

//aes加密
function encrypt(word) {
  let encrypted = "";
  if (typeof word == "string") {
    const srcs = CryptoJS.enc.Utf8.parse(word);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  } else if (typeof word == "object") {
    //对象格式的转成json字符串
    const data = JSON.stringify(word);
    const srcs = CryptoJS.enc.Utf8.parse(data);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  }
  return encrypted.ciphertext.toString();
}
// aes解密
function decrypt(word) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

export { verifyPassword, getCharCount, encrypt, decrypt };
