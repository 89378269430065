<template>
  <div class="player-block">
    <div class="width-1200 crumbs-block flex">
      <el-link href="/personal-center?activeName=purchased_data"
        >我的课程</el-link
      >
      <el-icon><ArrowRight /></el-icon>
      <span>详情</span>
    </div>

    <div class="width-1200 background-ffffff">
      <video
        v-if="_type == 'video'"
        id="video-block"
        :src="_url"
        controls="controls"
        controlsList="nodownload"
      >
        您的浏览器不支持 video 标签。
      </video>

      <audio
        v-if="_type == 'audio'"
        :src="_url"
        controls="controls"
        controlsList="nodownload"
      >
        您的浏览器不支持 audio 标签。
      </audio>

      <!-- <div class="operate flex">
        <div class="prev" style="cursor: no-drop">
          <el-icon><ArrowLeft /></el-icon>
        </div>
        <div class="border"></div>
        <div class="title">
          <img v-if="1" src="@/assets/images/video.png" />
          <img v-else src="@/assets/images/video.png" />
          <span>第六期-基于MaxEnt生态位模型的物种分布预测模与参数优化</span>
        </div>
        <div class="border"></div>
        <div class="next">
          <el-icon><ArrowRight /></el-icon>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import * as _request from '@/api/activities.js'
import router from '@/router/index.js'
import * as commonFunc from '@/util/common-func.js'

/**
 * @description 未登陆的返回首页
 */
const userInfo = inject('userInfo')
if (!userInfo.value.username) {
  router.push({
    path: '/',
    query: {}
  })
}

// /**
//  * @description 校验活动、课程是否有权限查看
//  */
// authority()
// function authority() {
//   let _requestApi = _request.ajax_is_take_activity
//   if (1) {
//     _requestApi = _request.ajax_is_take_course
//   }

//   _requestApi(_params)
//     .then((res) => {
//       if (res.error_code == 0) {
//         ElMessage({
//           message: res.error_msg,
//           type: 'success',
//           duration: 1000
//         })
//       }

//       router.push({
//         path: '/personal-center',
//         query: {
//           activeName: 'order_activity'
//         }
//       })
//       _btnLoading.value = false
//     })
//     .finally((err) => {
//       _btnLoading.value = false
//       console.log('catch: ', err)
//     })
// }

onMounted(() => {
  document.addEventListener('contextmenu', function (e) {
    e.preventDefault()
  })
})

/**
 * @description 解密后的url
 */
const _url = ref(commonFunc.decrypt(router.currentRoute.value.query.url))
const _type = ref(router.currentRoute.value.query.type)
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.player-block {
  background: #f4f4f4;
  padding-bottom: 60px;
  padding-top: 30px;

  .banner {
    width: 100%;
  }

  .width-1200 {
    padding: 30px;
    box-sizing: border-box;
  }

  .crumbs-block {
    margin: 0 auto 25px auto;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    padding: 0;

    .el-icon {
      margin: 0 6px;
    }

    span {
      color: #cccccc;
    }
  }

  video,
  audio {
    width: 100%;
  }

  .operate {
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .prev,
    .next {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border: 2px solid #d9d9d9;
      border-radius: 4px 1px 1px 4px;
      font-size: 18px;
      color: #666666;
      cursor: pointer;
      text-align: center;
      line-height: 40px;
      box-sizing: border-box;

      &:hover {
        background-color: #ecf5ff;
        color: #409eff;
        border-color: #409eff;
      }
    }

    .next {
      border-radius: 1px 4px 4px 1px;
    }

    .border {
      width: 210px;
      height: 1px;
      background: #e6e6e6;
    }

    .title {
      width: 520px;
      height: 32px;
      background: #f0f0f0;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 4px;
      box-sizing: border-box;

      span {
        flex-grow: 1;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>
