<template>
  <div class="join-activities-block">
    <div class="width-1200 crumbs-block flex">
      <el-link href="/course" target="_blank">课程购买</el-link>
      <el-icon><ArrowRight /></el-icon>
      <span>表单提交</span>
    </div>

    <div class="width-1200 background-ffffff form-block">
      <div class="title">联系信息</div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
      >
        <div class="form-card">
          <h5>课程信息</h5>
          <el-form-item label="课程名称">
            <!-- <el-input v-model="ruleForm.name" /> -->
            <p class="inhibit-input-text">{{ _dataInfo.course_name || '' }}</p>
          </el-form-item>

          <el-form-item label="课程介绍">
            <!-- <el-input v-model="ruleForm.name" /> -->
            <p class="inhibit-input-text">
              {{ _dataInfo.course_des || '' }}
            </p>
          </el-form-item>

          <el-form-item label="课程价格">
            <!-- <el-input v-model="ruleForm.name" /> -->
            <p class="inhibit-input-text">
              {{ _dataInfo.price_info ? _dataInfo.price_info.price : '' }}元
            </p>
          </el-form-item>
        </div>

        <div class="form-card">
          <h5>个人信息</h5>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" />
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input v-model="ruleForm.mobile" />
          </el-form-item>
        </div>

        <div class="form-card">
          <h5>报销信息</h5>
          <el-form-item label="是否需要发票">
            <el-select
              v-model="ruleForm.invoice_level"
              placeholder="不需要开票（享受折扣，详询客服）"
            >
              <el-option
                v-for="item in _invoiceLevelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <!-- <p class="tip">
              请与导师或本单位财务负责人确认可报销的发票类型后再提交。
            </p> -->
          </el-form-item>
        </div>

        <div class="form-card">
          <h5>付款凭证</h5>
          <el-form-item label="支付金额" prop="payment_amount">
            <el-input type="number" v-model="ruleForm.payment_amount" />
          </el-form-item>
          <el-form-item label="付款凭证" prop="pay_img">
            <div class="flex upload-block">
              <!-- :limit="1" -->
              <!-- :on-exceed="handleExceed" -->
              <el-upload
                :before-upload="beforeAvatarUpload"
                :on-error="handleError"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :action="`${_uploadApi}/api/m/activity/upload_img`"
                list-type="picture-card"
                ref="imgUpload"
                v-model:file-list="_uploadFileList"
              >
                <!-- :headers="{
        // Authorization: "Bearer " + this.$store.getters.access_token,
                }" -->
                <!-- :auto-upload="false" -->
                <!-- action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15" -->
                <el-icon><Plus /></el-icon>

                <template #file="{ file }">
                  <div>
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.url"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <el-icon><zoom-in /></el-icon>
                      </span>
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file)"
                      >
                        <el-icon><Delete /></el-icon>
                      </span>
                    </span>
                  </div>
                </template>
              </el-upload>

              <div class="important-notice flex">
                <img src="@/assets/images/icon_remind.png" />
                <div>
                  <p>
                    请上传jpg、png格式的付款凭证截图。上传的文件大小不能超20MB。上传成功后请检查缩略图是否正常显示，若不正常，请重新上传!
                  </p>
                </div>
              </div>
            </div>
          </el-form-item>

          <!-- 支付方式 -->
          <PaymentMethod style="width: 800px" />
        </div>

        <div class="form-card">
          <h5>其他</h5>

          <el-form-item label="推荐">
            <el-input v-model="ruleForm.invited_code" />
            <p class="tip">
              请输入推荐您参加本次活动的媒体（公众号）上所注明的随机推荐码，以获得相应积分奖励（积分可兑换礼品或现金）
            </p>
          </el-form-item>

          <el-form-item label="备注">
            <el-input
              v-model="ruleForm.remarks"
              placeholder="请在此输入您的特殊需求，中英文均可"
              type="textarea"
            />
            <p class="tip">您想备注的信息可放在此处。</p>
          </el-form-item>
        </div>

        <div class="important-notice flex">
          <img src="@/assets/images/icon_remind.png" />
          <div>
            <h4>重要提示 Important Notice</h4>
            <p>提交后请务必添加客服微信加入报名群，并关注邮箱里的重要通知。</p>
          </div>
        </div>

        <div class="operate">
          <el-button
            class="submit-btn"
            type="primary"
            @click="submitForm(ruleFormRef)"
            :loading="_btnLoading"
          >
            信息确认无误，立即提交
          </el-button>
        </div>
      </el-form>
    </div>

    <!-- 图片查看 -->
    <el-dialog v-model="dialogVisible">
      <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, inject } from 'vue'
import { genFileId } from 'element-plus'
import * as _request from '@/api/activities.js'
import { ElMessage } from 'element-plus'
import router from '@/router/index.js'
import PaymentMethod from '@/components/PaymentMethod'

console.log('process.env.VUE_APP_UPLOAD_API', process.env.VUE_APP_UPLOAD_API)
const _uploadApi = process.env.VUE_APP_UPLOAD_API
const userInfo = inject('userInfo')

/**
 * @description props参数
 */
const _propsData = defineProps({
  data: {
    type: Boolean,
    required: true
  },
  type: {
    type: String,
    default: 'video'
  }
})

/**
 * @description 信息获取
 */
const _dataInfo = ref({})
getDataInfo()
function getDataInfo() {
  const _params = {
    course_id: router.currentRoute.value.query.id
  }
  _request.ajax_course_info(_params).then((res) => {
    console.log('res', res.data)
    _dataInfo.value = res.data
  })
}

/**
 * @description form表单相关
 */
const _uploadFileList = ref([]) // 付款凭证list
const formSize = ref('default')
const ruleFormRef = ref()
const _btnLoading = ref(false)
const _invoiceLevelOptions = [
{
    value: 1,
    label: '尽快开票（需入群后确认开票信息）'
  },
  {
    value: 2,
    label: '不需要开票（享受折扣，详询客服）'
  },
  {
    value: 3,
    label: '延迟开票（需要发票时联系客服）'
  },
]
const ruleForm = reactive({
  course_id: router.currentRoute.value.query.id, // 活动id
  pay_img: '', // 支付凭证照片
  tag_id: router.currentRoute.value.query.service, // 服务id
  name: userInfo.value.username, // 姓名
  mobile: userInfo.value.mobile, // 电话
  invited_code: '', // 推荐码
  remarks: '', // 备注
  ID_card: '', // 身份证
  weixin: '', // 微信
  email: '', //
  unit_name: '', // 单位名称
  unit_tax_number: '', // 单位税号
  invoice_type: '', // 发票类型
  payment_amount: '', // 实际支付金额
  invoice_level: '' // 发票信息
})

// 校验文件上传
const uploadFile = (rule, value, callback) => {
  if (_uploadFileList.value.length === 0) {
    callback(new Error('请上传文件'))
  } else {
    callback()
  }
}

const rules = reactive({
  pay_img: [
    {
      required: true,
      validator: uploadFile,
      trigger: 'blur'
    }
  ],
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  mobile: [{ required: true, message: '请输入联系电话', trigger: 'blur' }]
})

/**
 * @description 表单提交
 */
const submitForm = async (formEl) => {
  console.log('imgUpload', imgUpload.value, _uploadFileList.value)
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      _btnLoading.value = true
      ruleForm.pay_img = _uploadFileList.value[0].response.data
      const _params = ruleForm
      _request
        .ajax_course_take(_params)
        .then((res) => {
          if (res.error_code == 0) {
            ElMessage({
              message: res.error_msg,
              type: 'success',
              duration: 1000
            })
          }
          router.push({
            path: '/personal-center',
            query: {
              activeName: 'order_course'
            }
          })
          _btnLoading.value = false
        })
        .finally((err) => {
          _btnLoading.value = false
          console.log('catch: ', err)
        })
    } else {
      _btnLoading.value = false
      console.log('error submit!', fields)
    }
  })
}

/**
 * @description 图片上传
 */
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const imgUpload = ref()

/**
 * @description 限制上传文件的大小
 */
const beforeAvatarUpload = (file) => {
  // if (file.type !== 'image/jpeg') {
  //   ElMessage.error('Avatar picture must be JPG format!')
  //   return false
  // }
  console.log('file', file)
  const isOverSize = file.size / 1024 / 1024 <= 20
  if (!isOverSize) {
    ElMessage({
      message: '上传的文件大小不能超过20MB',
      type: 'error',
      duration: 1000
    })
  }
  return isOverSize
}

/**
 * @description 上传失败的钩子函数
 */
const handleError = (err, file, fileList) => {
  console.log('err, file, fileList', err, file, fileList)
  ElMessage({
    message: err.error_msg,
    type: 'error',
    duration: 1000
  })
}

/**
 * @description 上传成功的钩子函数
 */
const handleSuccess = (response, file, fileList) => {
  // 只保留一张图片
  console.log('response, file, fileList', response, file, fileList)
  if (_uploadFileList.value.length > 1) {
    _uploadFileList.value.splice(0, 1)
  }
}

/**
 * @description 图片删除
 */
const handleRemove = (file) => {
  console.log(file)
  _uploadFileList.value = []
  imgUpload.value.clearFiles()
}

/**
 * @description 图片查看
 */
const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url ? file.url : ''
  dialogVisible.value = true
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.join-activities-block {
  background: #f4f4f4;
  padding-bottom: 60px;
  padding-top: 0;
  overflow: hidden;

  .width-1200 {
    width: 1080px;
    margin: 0 auto;
  }

  .crumbs-block {
    margin: 25px auto 25px auto;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #666666;

    .el-icon {
      margin: 0 6px;
    }

    span {
      color: #cccccc;
    }
  }

  .form-block {
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 60px 60px 60px;
    box-sizing: border-box;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 0 0 30px 0;
      display: flex;
      align-items: center;

      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 20px;
        background: #0e6eb8;
        border-radius: 2px;
        margin-right: 12px;
      }
    }

    .form-card {
      width: 100%;
      background: #f2f8fc;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 20px;
    }

    h5 {
      font-size: 16px;
      font-weight: 500;
      color: #2a2a2a;
      line-height: 1;
      margin: 0 0 24px 0;
    }

    .inhibit-input-text {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      line-height: 32px;
      margin: 0;
    }

    .tip {
      font-size: 14px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 10px 0 0 0;
    }

    .el-input,
    .el-select,
    .el-textarea {
      width: 620px;
    }

    .upload-block {
      flex-grow: 1;
      justify-content: space-between;

      .important-notice {
        flex-shrink: 0;
        width: 390px;
      }
    }
  }

  .important-notice {
    background: #fff5f5;
    border: 1px solid #ffd6d6;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 30px;
    margin-bottom: 40px;

    img {
      width: 18px;
      height: 18px;
      margin: 0 6px 0 0;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 14px;
      color: #666666;
      line-height: 18px;
      margin: 0;
    }
  }

  .operate {
    text-align: center;

    .submit-btn {
      width: 300px;
      height: 50px;
      background: #0e6eb8;
      border-radius: 25px;

      &:hover {
        background: #0099ff;
      }
    }
  }
}
</style>
