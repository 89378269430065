<template>
  <el-dialog
    v-model="dialogVisible"
    title="修改密码"
    width="500px"
    class="dialog-block"
    :before-close="handleClose"
  >
    <div class="register-form-block">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        :size="formSize"
        status-icon
      >
        <el-form-item label="旧密码" prop="old_password">
          <el-input
            type="password"
            v-model="ruleForm.old_password"
            clearable
            show-password
          />
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model="ruleForm.password"
            clearable
            show-password
          />
          <p>最低应有8位字符组成</p>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirm_password">
          <el-input
            type="password"
            v-model="ruleForm.confirm_password"
            show-password
            clearable
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          class="login-submit"
          type="primary"
          @click="submitForm(ruleFormRef)"
        >
          确 定
        </el-button>
      </span>
      <!-- <div class="forget-register flex">
        已有账户，立即
        <span class="register" @click="toLogin">登录</span>
      </div> -->
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, inject, nextTick } from 'vue'
import * as _request from '@/api/login.js'
import { ElMessage } from 'element-plus'
import Cookies from 'js-cookie'
import router from '@/router/index.js'

const dialogVisible = ref(true)
const userInfoChange = inject('userInfoChange')
const loginDialogVisibleChange = inject('loginDialogVisibleChange')
const changePasswordDialogVisibleChange = inject(
  'changePasswordDialogVisibleChange'
)

/**
 * @description 表单
 */
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = reactive({
  old_password: '',
  password: '',
  confirm_password: ''
})

//验证密码是否合法
const password = (rule, value, callback) => {
  if (value == '') {
    return callback(new Error('密码不能为空'))
    // callback();
  } else if (value.length >= 8) {
    callback()
  } else {
    callback(new Error('最低应由8位字符组成'))
  }
}

//验证密码是否合法，且与密码相同
const confirmPassword = (rule, value, callback) => {
  console.log('value', value)
  if (value == '') {
    return callback(new Error('密码不能为空'))
    // callback();
  } else if (value.length >= 8 && value == ruleForm.password) {
    callback()
  } else {
    callback(new Error('密码和确认密码应保持一致'))
  }
}

const rules = reactive({
  old_password: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
  password: [
    { required: true, validator: password, trigger: 'blur' }
    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
  ],
  confirm_password: [
    { required: true, validator: confirmPassword, trigger: 'blur' }
    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
  ]
})

/**
 * @description 退出
 */
 function logout() {
  const _params = {}
  _request.logout(_params).then((res) => {
    localStorage.removeItem('userInfo')
    sessionStorage.clear()
    Cookies.remove('course')
    changePasswordDialogVisibleChange()
    userInfoChange({})

    setTimeout(() => {
      router.push({
        path: '/',
        query: {}
      })
    }, 300)
  })
}

/**
 * @description 提交
 */
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      const _params = {
        old_password: ruleForm.old_password,
        password: ruleForm.password
      }
      _request.ajax_reset_pass(_params).then((res) => {
        // console.log('res', res)
        if (res.error_code == 0) {
          ElMessage({
            message: '修改成功，请重新登录',
            type: 'success',
            duration: 1000
          })
          
          logout()
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

const handleClose = (done) => {
  changePasswordDialogVisibleChange()
}

// /**
//  * @description 登录
//  */
// function toLogin() {
//   changePasswordDialogVisibleChange()
//   nextTick(() => {
//     loginDialogVisibleChange()
//   }, 100)
// }
</script>
<style lang="scss" scoped>
.dialog-block {
  .register-form-block {
    box-sizing: border-box;
    /* padding: 40px 40px 0 40px; */

    p {
      margin: 5px 0 0 0;
      line-height: 1;
      color: #999999;
      font-size: 12px;
    }

    .email.flex {
      justify-content: space-between;
      align-items: center;
      .el-input {
        flex-grow: 1;
        width: 260px;
      }

      .btn {
        width: 80px;
        height: 38px;
        background: #0e6eb8;
        color: #ffffff;
        text-align: center;
        line-height: 36px;
        flex-shrink: 0;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
          background: #0099ff;
        }
      }
    }
  }

  .forget-register {
    box-sizing: border-box;
    padding: 0 20px 10px 20px;
    margin-top: 18px;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: bold;
    color: #656565;
    line-height: 1;
  }

  .forget,
  .register {
    cursor: pointer;
  }

  .register {
    color: #0e6eb8;
  }
}
</style>