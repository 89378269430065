<template>
  <div class="order-list-block">
    <div class="card-block" v-for="item in _propsData.data" :key="item.id">
      <div class="orderid flex">
        <div>
          订单编号：
          <span class="count">{{ item.order_id }}</span>
          <!-- 订单状态,1，待审核；2，驳回；3，已完成；4，废弃 -->
          <span v-if="item.status == 1" class="status pay">待审核</span>
          <span v-else-if="item.status == 2" class="status reject">已驳回</span>
          <span v-else-if="item.status == 3" class="status finish">已完成</span>
          <span v-else-if="item.status == 4" class="status scrap">已废弃</span>
        </div>
        <span class="date">{{ item.creation_time }}</span>
      </div>
      <div class="info flex">
        <img
          class="thumbnail"
          :src="
            item.course_info.course_cover
              ? item.course_info.course_cover
              : _defaultImg
          "
        />
        <div class="flex-grow1">
          <h2>{{ item.course_info.course_name }}</h2>
          <!-- <p>
            <span class="label">时间：</span
            >{{ item.course_info.course_date }}
          </p> -->
          <!-- <p>
            <span class="label">地点：</span
            >{{ item.course_info.course_area }}
          </p> -->
          <p>
            <span class="label">活动介绍：</span>
            <span>
              {{ item.course_info.course_des }}
            </span>
          </p>
          <p>
            <span class="label">价格：</span>
            <span class="price">¥{{ item.price_info.price }}</span>
          </p>
        </div>
      </div>
      <div class="operation flex">
        <div class="key">
          <!-- <span class="label">参会密钥：</span>
          <span>{{ item.invite_code }}</span> -->
        </div>
        <div class="btn-block flex">
          <!-- <div class="btn flex" @click="download">
            <img src="@/assets/images/download.png" />发票下载
          </div> -->
          <div
            v-if="item.status == 2"
            class="btn flex"
            @click="toRemittanceVoucher(item)"
          >
            <img src="@/assets/images/upload.png" />上传成功汇款凭证
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 成功汇款凭证 -->
  <OrderRemittanceVoucher
    @close="closeReturn"
    :id="_orderId"
    type="course"
    v-if="_OrderRemittanceVoucherDialogVisible"
  />
</template>

<script setup>
// defineEmits
import { ref, onMounted, reactive, defineProps } from 'vue'
import * as _request from '@/api/user.js'
import router from '@/router/index.js'
import OrderRemittanceVoucher from './order_remittance_voucher.vue'

const _defaultImg = require('@/assets/images/banner.png') // 这里需要换成绝对路径

/**
 * @description props参数
 */
const _propsData = defineProps({
  data: {
    type: Array,
    required: true,
    default: () => []
  }
})

/**
 * @description 汇款凭证
 */
const _orderId = ref('')
const _OrderRemittanceVoucherDialogVisible = ref(false)
function toRemittanceVoucher(_item) {
  _OrderRemittanceVoucherDialogVisible.value = true
  _orderId.value = _item.id
}

/**
 * @description 关闭回调
 */
function closeReturn() {
  _OrderRemittanceVoucherDialogVisible.value = false
  _orderId.value = ''
}

/**
 * @description 发票下载
 */
function download() {}

/**
 * @description 跳转已购课程详情、目录页面
 */
function toPurchasedDataDetail(_item) {
  router.push({
    path: '/purchased-data-detail',
    query: {
      id: _item
    }
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.order-list-block {
  .card-block {
    width: 100%;
    // height: 330px;
    background: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 12px 20px;
    box-sizing: border-box;

    .orderid {
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: #7f7f7f;
      line-height: 1;

      .count {
        color: #333333;
        margin: 0 15px 0 0;
      }

      .status {
        display: inline-block;
        width: 70px;
        height: 24px;
        border: 1px solid #ff6d39;
        border-radius: 12px;
        font-size: 13px;
        font-weight: 500;
        color: #ff6d39;
        line-height: 24px;
        text-align: center;

        &.scrap {
          border-color: #999999;
          color: #999999;
        }

        &.finish {
          border-color: #0e6eb8;
          color: #0e6eb8;
        }

        &.reject {
          border-color: #ff4848;
          color: #ff4848;
        }

        &.pay {
          border-color: #ff6d39;
          color: #ff6d39;
        }
      }

      .date {
        font-size: 13px;
        color: #999999;
      }
    }

    .info {
      margin: 13px 0 12px 0;
      padding-bottom: 15px;
      box-sizing: border-box;
      border-bottom: 1px solid #d9d9d9;
      justify-content: space-between;

      .thumbnail {
        width: 300px;
        height: 213px;
        margin: 0 20px 0 0;
        border-radius: 6px;
        flex: none; /** 使项目不能被压缩或放大*/
      }

      h2 {
        font-size: 16px;
        font-weight: bold;
        color: #292929;
        line-height: 1;
        margin: 10px 0 18px 0;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 1;
        display: flex;
        line-height: 20px;
        margin: 0 0 10px 0;
      }

      .label {
        display: inline-block;
        flex: none; /** 使项目不能被压缩或放大*/
        width: 80px;
        color: #7f7f7f;
      }

      .price {
        font-size: 20px;
        font-weight: 500;
        color: #ff6d39;
      }
    }

    .operation {
      justify-content: space-between;
      align-items: center;

      .key {
        font-size: 14px;
        font-weight: 500;
        color: #333333;

        .label {
          color: #7f7f7f;
        }
      }

      .btn {
        border: 1px solid #d9d9d9;
        height: 36px;
        background: #ffffff;
        border-radius: 18px;
        line-height: 36px;
        font-size: 15px;
        text-align: center;
        color: #0e6eb8;
        padding: 0 20px;
        margin-left: 15px;
        cursor: pointer;
        align-items: center;

        img {
          width: 18px;
          margin: 0 3px 0 0;
        }

        &:hover {
          background: #fafafa;
        }
      }
    }
  }
}
</style>
