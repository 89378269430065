import axios from "axios";
import { ElMessage } from "element-plus";
import Cookies from "js-cookie";
import router from "@/router/index.js";

// Cookies.get('cosmoplat-access-token')
// Cookies.set("access_token", data.access_token);
// Cookies.remove('access_token')
axios.defaults.withCredentials = true;

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 环境变量 url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // console.log("Cookies.get('session')", Cookies.get('session'))
    // do something before request is sent
    // if (Cookies.get('course')) {
    //   config.headers['Cookie'] = Cookies.get('course');
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    // }
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;
    console.log("response", response, res.error_code);
    if (res && res.error_code != 0) {
      ElMessage({
        message: res.error_msg,
        type: "error",
        duration: 3000,
      });
      if (res && res.error_msg == "请登录") {
        Cookies.remove("course");
        localStorage.clear();
        router.push({
          path: "/",
          query: {},
        });
        // location.reload();
      }
      return Promise.reject(new Error(res.error_msg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    console.log("error", error); // for debug
    // ElMessage({
    //   message: error.error_msg || error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

export default service;
