<template>
  <div class="card-list-block">
    <el-row :gutter="20">
      <el-col
        v-for="item in _propsData.data"
        :key="item.id"
        :span="_propsData.colSpanCount"
      >
        <div class="card-block">
          <div class="margin-16">
            <img
              class="thumbnail"
              :src="
                item.activity_info
                  ? item.activity_info.act_focus_url
                  : _defaultImg
              "
            />
            <h5>{{ item.activity_info.activity_name }}</h5>
            <p style="height: 48px;">
              {{
                item.activity_info.activity_remarks && item.activity_info.activity_remarks.length > 35
                  ? item.activity_info.activity_remarks.slice(0, 35) + '...'
                  : item.activity_info.activity_remarks
              }}
            </p>

            <div class="split-line"></div>

            <div class="flex purchase">
              <div>
                <p>价格（元）</p>
                <!-- <p v-if="item.price_info.length == 0">
                  <span class="price">0</span>
                </p> -->
                <!-- <p v-for="priceItem in item.price_info" :key="priceItem.level">
                  <span class="price" v-if="priceItem.level == 2">{{
                    priceItem.price
                  }}</span>
                </p> -->
                <p>
                  <span class="price">{{
                    item.price_info.price || 0
                  }}</span>
                </p>
              </div>
              <!-- <div
                v-if="!item.is_take && item.price_info.length == 0"
                class="free-btn"
                @click="toPurchasedDataDetail(item)"
              >
                免费
              </div> -->
              <!-- <div v-for="priceItem in item.price_info" :key="priceItem.level">
                <div
                  v-if="!item.is_take && priceItem.level == 2"
                  class="purchase-btn"
                  @click="toCommodityDetails(item)"
                >
                  购买
                </div>
                <div
                  v-if="!item.is_take && priceItem.level == 1"
                  class="vip-btn"
                  @click="toPurchasedDataDetail(item)"
                >
                  VIP专享
                </div>
              </div> -->
              <div
                class="free-btn"
                @click="toPurchasedDataDetail(item)"
              >
                查看
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
// defineEmits
import { ref, onMounted, reactive, defineProps } from 'vue'
// import * as _request from '@/api/user.js'
import router from '@/router/index.js'

const _defaultImg = require('@/assets/images/banner.png') // 这里需要换成绝对路径

/**
 * @description props参数
 */
const _propsData = defineProps({
  data: {
    type: Array,
    required: true,
    default: () => []
  },
  colSpanCount: {
    type: Number,
    default: 6
  }
})

/**
 * @description 跳转商品详细信息
 */
function toCommodityDetails(_item) {
  router.push({
    path: '/commodity-detail-activity',
    query: {
      id: _item.activity_id
    }
  })
}

/**
 * @description 跳转已购课程详情、目录页面
 */
function toPurchasedDataDetail(_item) {
  router.push({
    path: '/purchased-data-detail-activity',
    query: {
      id: _item.activity_id
    }
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-list-block {
  .card-block {
    width: 100%;
    height: 380px;
    background: #f5f5f5;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 20px;

    .thumbnail {
      width: 100%;
      height: 180px;
      background: #ffffff;
    }

    h5 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 1;
      margin: 20px 0 12px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
      margin: 0;
    }

    .split-line {
      margin: 10px 0 0 0;
      height: 1px;
      width: 100%;
      background-color: #cccccc;
    }

    .purchase {
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 0 0;

      p {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 1;
        margin: 8px 0;
      }

      .price {
        font-size: 18px;
        font-weight: 500;
        color: #ff6d39;
      }

      .purchase-btn {
        width: 80px;
        height: 36px;
        background: #0e6eb8;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #0099ff;
        }
      }

      .free-btn {
        width: 80px;
        height: 36px;
        background: #ffffff;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #0e6eb8;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background: #fafafa;
        }
      }

      .vip-btn {
        width: 80px;
        height: 36px;
        background: #ff6d39;
        border-radius: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        &:hover {
          background: #ef8d6a;
        }
      }
    }
  }
}
</style>
