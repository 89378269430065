// vue-router4
import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/home/index";
import NewsInfo from "@/views/news_info/index";
import Activity from "@/views/activity/list";
import Course from "@/views/course/list";
import CommodityDetailActivity from "@/views/activity/commodity_detail";
import CommodityDetailCourse from "@/views/course/commodity_detail";
import PurchasedDataDetailActivity from "@/views/activity/purchased_data_detail";
import PurchasedDataDetailCourse from "@/views/course/purchased_data_detail";
import JoinActivities from "@/views/activity/join_form";
import JoinCourse from "@/views/course/join_form";
import Player from "@/views/player/index";
import PersonalCenter from "@/views/personal_center/index";
import PersonalInformation from "@/views/personal_center/information";
import AboutUs from "@/views/about_us/index";
import ServiceDescription from "@/views/service_description/index";
import RecruitmentForm from "@/views/lecturers/recruitment_form";


const router = createRouter({
  history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    // {
    //   path: "/login",
    //   name: "login",
    //   component: Login,
    //   show: false, // 权限展示
    //   meta: {
    //     title: "登录",
    //     icon: "login",
    //     headMenu: false,
    //     rightChat: false,
    //   },
    // },
    {
      path: "",
      redirect: "/home",
      show: false, // 权限展示
    },
    {
      path: "/home",
      name: "home",
      component: Home,
      show: true, // 权限展示
      meta: {
        title: "首页",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },
    {
      path: "/activity",
      name: "activity",
      component: Activity,
      show: true, // 权限展示
      meta: {
        title: "会议沙龙",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },
    {
      path: "/course",
      name: "course",
      component: Course,
      show: true, // 权限展示
      meta: {
        title: "课程购买",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },
    {
      path: "/commodity-detail-activity",
      name: "commodity-detail-activity",
      component: CommodityDetailActivity,
      show: false, // 权限展示
      meta: {
        title: "详情",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },
    {
      path: "/commodity-detail-course",
      name: "commodity-detail-course",
      component: CommodityDetailCourse,
      show: false, // 权限展示
      meta: {
        title: "详情",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },
    // {
    //   path: "/news-info",
    //   name: "news-info",
    //   component: NewsInfo,
    //   show: true, // 权限展示
    //   meta: {
    //     title: "最新资讯",
    //     icon: "",
    //     headMenu: true,
    //     rightChat: true,
    //   },
    // },
    {
      path: "/recruitment-lecturers",
      name: "recruitment-lecturers",
      component: RecruitmentForm,
      show: true, // 权限展示
      meta: {
        title: "讲师招募",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },
    {
      path: "/service-description",
      name: "service-description",
      component: ServiceDescription,
      show: true, // 权限展示
      meta: {
        title: "服务说明",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },
    {
      path: "/about-us",
      name: "about-us",
      component: AboutUs,
      show: true, // 权限展示
      meta: {
        title: "关于我们",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },

    {
      path: "/personal-center",
      name: "personal-center",
      component: PersonalCenter,
      show: false, // 权限展示
      meta: {
        title: "个人中心",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },

    {
      path: "/personal-information",
      name: "personal-information",
      component: PersonalInformation,
      show: false, // 权限展示
      meta: {
        title: "个人信息",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },

    {
      path: "/purchased-data-detail-activity",
      name: "purchased-data-detail-activity",
      component: PurchasedDataDetailActivity,
      show: false, // 权限展示
      meta: {
        title: "详情",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },

    {
      path: "/purchased-data-detail-course",
      name: "purchased-data-detail-course",
      component: PurchasedDataDetailCourse,
      show: false, // 权限展示
      meta: {
        title: "详情",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },

    {
      path: "/player",
      name: "player",
      component: Player,
      show: false, // 权限展示
      meta: {
        title: "详情",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },
    {
      path: "/join-activities",
      name: "join-activities",
      component: JoinActivities,
      show: false, // 权限展示
      meta: {
        title: "报名活动",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },

    {
      path: "/join-course",
      name: "join-course",
      component: JoinCourse,
      show: false, // 权限展示
      meta: {
        title: "课程购买",
        icon: "",
        headMenu: true,
        rightChat: true,
      },
    },

    // {
    //   path: "/monitor",
    //   name: "monitor",
    //   component: Layout,
    //   show: true, // 权限展示
    //   meta: {
    //     title: "实时监测",
    //     icon: "monitor",
    //     headMenu: true,
    //     rightChat: true,
    //   },
    //   children: [
    //     // UserHome will be rendered inside User's <router-view>
    //     // when /users/:username is matched
    //     {
    //       path: "/monitor/station",
    //       name: "station",
    //       component: MonitorStation,
    //       show: true, // 权限展示
    //       meta: {
    //         title: "场站概况",
    //         icon: "",
    //         headMenu: true,
    //         rightChat: true,
    //       },
    //     },
    //     {
    //       path: "/monitor/crew",
    //       name: "crew",
    //       component: MonitorCrew,
    //       show: true, // 权限展示
    //       meta: {
    //         title: "机组情况",
    //         icon: "",
    //         headMenu: true,
    //         rightChat: true,
    //       },
    //     },
    //     {
    //       path: "/monitor/crew/detail",
    //       name: "crewDetail",
    //       component: MonitorCrewDetail,
    //       show: false, // 权限展示
    //       meta: {
    //         title: "机组情况",
    //         icon: "",
    //         headMenu: true,
    //         rightChat: true,
    //       },
    //     },
    //   ],
    // },
  ],
});

export default router;
