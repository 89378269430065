<template>
  <div class="meeting-block">
    <!-- <img class="banner" src="@/assets/images/banner2.png" /> -->

    <!-- 课程购买 -->
    <div class="material-purchase-block width-1200">
      <!-- <div class="flex">
        <div>
          <h3>MATERIAL PURCHASE</h3>
          <h2>课程购买</h2>
        </div>
        <div class="more flex">
          了解更多<img src="@/assets/images/arrow_more.png" />
        </div>
      </div> -->
      <div class="tabs-blcok flex">
        <div
          :class="{ 'tabs-item-block': true, active: _activeName == 'all' }"
          @click="tabChange('all', -1)"
        >
          <span>全部课程</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
        <div
          :class="{ 'tabs-item-block': true, active: _activeName == 'free' }"
          @click="tabChange('free', 0)"
        >
          <span>免费课程</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
        <div
          :class="{ 'tabs-item-block': true, active: _activeName == 'vip' }"
          @click="tabChange('vip', 1)"
        >
          <span>会员专享</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
        <div
          :class="{
            'tabs-item-block': true,
            active: _activeName == 'purchased'
          }"
          @click="tabChange('purchased', 2)"
        >
          <span>付费课程</span>
          <el-icon><CaretBottom /></el-icon>
        </div>
      </div>

      <!-- 卡片样式的商品列表 -->
      <CardListCourse :data="_cardList" />

      <div class="pagination-block">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="pageTotal"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import * as _request from '@/api/activities.js'
import router from '@/router/index.js'
import CardListCourse from '../course/components/CardList.vue'

/**
 * @description 分类数据切换
 */
const _activeName = ref('all')
const _activeLevel = ref(-1)
function tabChange(_val, _level) {
  _activeName.value = _val
  _activeLevel.value = _level
  getDataList()
}

/**
 * @description 分页数据获取
 */
const _cardList = ref([])
const currentPage = ref(1)
const pageSize = ref(8)
const pageTotal = ref(0)
const small = ref(true)
getDataList()
function getDataList() {
  const _params = {
    level: _activeLevel.value,
    page: currentPage.value,
    limit: pageSize.value
  }
  _request.ajax_course_list(_params).then((res) => {
    pageTotal.value = res.data.count || 0
    _cardList.value = res.data.list || []
  })
}

/**
 * @description 分页切换
 * @param {*} val
 */
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
  currentPage.value = val
  getDataList()
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.meeting-block {
  margin-bottom: 60px;

  .banner {
    width: 100%;
  }

  .material-purchase-block {
    margin-top: 60px;
  }

  .material-purchase-block {
    & > .flex {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 39px;
    }

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #c6dbeb;
      line-height: 1;
      margin: 0;
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 16px 0 0 0;
    }

    .more {
      width: 120px;
      height: 40px;
      border: 2px solid #0e6eb8;
      border-radius: 20px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 40px;
      align-items: center;
      justify-content: center;

      img {
        margin: 0 0 0 9px;
      }
    }

    .tabs-blcok {
      border-bottom: 2px solid #e6e6e6;
      padding: 0 0 16px 0;
      justify-content: flex-start;

      .tabs-item-block {
        width: 160px;
        height: 40px;
        background: #f0f0f0;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #0e6eb8;
        line-height: 40px;
        text-align: center;
        position: relative;
        cursor: pointer;
        margin-right: 16px;

        &:hover {
          background: #0e6eb8;
          color: #ffffff;
        }

        .el-icon {
          position: absolute;
          bottom: -10px;
          left: 50%;
          margin-left: -8px;
          display: none;
        }

        &.active {
          background: #0e6eb8;
          color: #ffffff;

          .el-icon {
            display: block;
            color: #0e6eb8;
          }
        }
      }
    }
  }

  .pagination-block {
    border-top: 1px solid #e6e6e6;
    padding-top: 30px;
  }
}
</style>
