<template>
  <div class="container">
    <Header v-if="headMenuShow" :menuDefaultActive="menuDefaultActive"></Header>

    <!-- 客服浮动窗 -->
    <Chat v-if="rightChatShow" />

    <!-- 占位，撑开header的高度间隙 -->
    <div style="height: 80px; width: 100%"></div>

    <div v-if="headMenuShow">
      <router-view></router-view>
    </div>
    <div v-else>
      <!-- 适用于登录、注册等 没有header和菜单的页面 -->
      <router-view></router-view>
    </div>

    <Footer v-if="headMenuShow"></Footer>

    <!-- 登录--放在这里是为了全局引用 -->
    <Login v-if="loginDialogVisible" />
    <!-- 注册--放在这里是为了全局引用 -->
    <Register v-if="registerDialogVisible" />
    <!-- 忘记密码--放在这里是为了全局引用 -->
    <ForgetPassword v-if="forgetPasswordDialogVisible" />
    <!-- 修改密码--放在这里是为了全局引用 -->
    <ChangePassword v-if="changePasswordDialogVisible" />
    <!-- 服务条款--放在这里是为了全局引用 -->
    <FuWuTiaoKuan v-if="FuWuTiaoKuanDialogVisible" />
    <!-- 隐私协议--放在这里是为了全局引用 -->
    <YinSiTiaoKuan v-if="YinSiTiaoKuanDialogVisible" />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Chat from './components/Chat.vue'
import Login from './components/Login.vue'
import Register from './components/Register.vue'
import ForgetPassword from './components/ForgetPassword.vue'
import FuWuTiaoKuan from './components/FuWuTiaoKuan.vue'
import YinSiTiaoKuan from './components/YinSiTiaoKuan.vue'
import ChangePassword from './components/ChangePassword.vue'
import Cookies from 'js-cookie'
import { provide, ref } from 'vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Chat,
    Login,
    Register,
    ForgetPassword,
    FuWuTiaoKuan,
    YinSiTiaoKuan,
    ChangePassword
  },
  setup() {
    /**
     * @description 全局变量，用于控制login、register弹窗的展示隐藏
     */
    // 登录
    const loginDialogVisible = ref(false)
    const loginDialogVisibleChange = () => {
      loginDialogVisible.value = !loginDialogVisible.value
    }
    provide('loginDialogVisible', loginDialogVisible)
    provide('loginDialogVisibleChange', loginDialogVisibleChange)

    // 注册
    const registerDialogVisible = ref(false)
    const registerDialogVisibleChange = () => {
      registerDialogVisible.value = !registerDialogVisible.value
    }
    provide('registerDialogVisible', registerDialogVisible)
    provide('registerDialogVisibleChange', registerDialogVisibleChange)

    // 忘记密码
    const forgetPasswordDialogVisible = ref(false)
    const forgetPasswordDialogVisibleChange = () => {
      forgetPasswordDialogVisible.value = !forgetPasswordDialogVisible.value
    }
    provide('forgetPasswordDialogVisible', forgetPasswordDialogVisible)
    provide(
      'forgetPasswordDialogVisibleChange',
      forgetPasswordDialogVisibleChange
    )

    // 修改密码
    const changePasswordDialogVisible = ref(false)
    const changePasswordDialogVisibleChange = () => {
      changePasswordDialogVisible.value = !changePasswordDialogVisible.value
    }
    provide('changePasswordDialogVisible', changePasswordDialogVisible)
    provide(
      'changePasswordDialogVisibleChange',
      changePasswordDialogVisibleChange
    )

    // 服务条款
    const FuWuTiaoKuanDialogVisible = ref(false)
    const FuWuTiaoKuanDialogVisibleChange = () => {
      FuWuTiaoKuanDialogVisible.value = !FuWuTiaoKuanDialogVisible.value
    }
    provide('FuWuTiaoKuanDialogVisible', FuWuTiaoKuanDialogVisible)
    provide('FuWuTiaoKuanDialogVisibleChange', FuWuTiaoKuanDialogVisibleChange)

    // 隐私条款
    const YinSiTiaoKuanDialogVisible = ref(false)
    const YinSiTiaoKuanDialogVisibleChange = () => {
      YinSiTiaoKuanDialogVisible.value = !YinSiTiaoKuanDialogVisible.value
    }
    provide('YinSiTiaoKuanDialogVisible', YinSiTiaoKuanDialogVisible)
    provide(
      'YinSiTiaoKuanDialogVisibleChange',
      YinSiTiaoKuanDialogVisibleChange
    )

    // 个人信息
    const userInfo = ref({})
    // Cookies.get('course') &&
    userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {}
    const userInfoChange = (_res) => {
      userInfo.value = _res
    }
    provide('userInfo', userInfo)
    provide('userInfoChange', userInfoChange)

    // console.log('env------', process.env)
    // const configInfo = ref({
    //   uploadUrl: '/api/m/activity/upload_img' // 上传路径
    // })

    return {
      loginDialogVisible,
      loginDialogVisibleChange,
      registerDialogVisible,
      registerDialogVisibleChange,
      FuWuTiaoKuanDialogVisible,
      FuWuTiaoKuanDialogVisibleChange,
      YinSiTiaoKuanDialogVisible,
      YinSiTiaoKuanDialogVisibleChange,
      userInfo,
      userInfoChange,
      forgetPasswordDialogVisible,
      forgetPasswordDialogVisibleChange,
      changePasswordDialogVisible,
      changePasswordDialogVisibleChange
    }
  },
  data() {
    return {
      menuDefaultActive: '' // 默认选中的路由
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val, oldVal)
        this.menuDefaultActive = val.path
        // console.log('this.menuDefaultActive', this.menuDefaultActive, val.path)
      },
      // 深度监听
      deep: true
    }
  },
  created() {
    this.menuDefaultActive = this.$route.path
  },
  computed: {
    /**
     * @description header是否展示
     */
    headMenuShow() {
      // console.log('this.$route', this.$route)
      return this.$route ? this.$route.meta.headMenu : false
    },
    /**
     * @description 客服浮动窗口是否展示
     */
    rightChatShow() {
      return this.$route ? this.$route.meta.rightChat : false
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
}
</style>
