import request from "@/util/request";

/**
 * @description 登录
 */
 function login(data) {
  return request({
    url: "/api/m/ajax_login",
    method: "post",
    data
  });
}

/**
 * @description 注册
 */
function register(data) {
  return request({
    url: "/api/m/ajax_register",
    method: "post",
    data
  });
}

/**
 * @description 退出
 */
 function logout(data) {
  return request({
    url: "/api/m/passport/logout",
    method: "post",
    data
  });
}

/**
 * @description 编辑个人信息
 */
function ajax_user_edit(data) {
  return request({
    url: "/api/m/ajax_user_edit",
    method: "post",
    data
  });
}

/**
 * @description 编辑个人信息
 */
function ajax_user_info(data) {
  return request({
    url: "/api/m/ajax_user_info",
    method: "POST",
    data
  });
}

/**
 * @description 修改密码
 */
function ajax_reset_pass(data) {
  return request({
    url: "/api/m/ajax_reset_pass",
    method: "POST",
    data
  });
}

/**
 * @description 重置密码
 */
function ajax_find_pass(data) {
  return request({
    url: "/api/m/ajax_find_pass",
    method: "POST",
    data
  });
}

export { 
  login,
  register,
  logout,
  ajax_user_edit,
  ajax_user_info,
  ajax_reset_pass,
  ajax_find_pass
};
