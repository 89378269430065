<template>
  <div class="personal-information-block">
    <div class="width-1200 background-ffffff form-block">
      <div class="title">个人信息</div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
      >
        <div class="form-card">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" />
          </el-form-item>
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="ruleForm.mobile" />
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="ruleForm.email" disabled />
          </el-form-item>
          <el-form-item label="单位名称" prop="unit_name">
            <el-input v-model="ruleForm.unit_name" />
          </el-form-item>
          <el-form-item label="单位税号" prop="unit_num">
            <el-input v-model="ruleForm.unit_num" />
          </el-form-item>
          <el-form-item label="推荐码">
            <el-input disabled v-model="ruleForm.invite_code" />
          </el-form-item>
          <!-- <el-form-item label="快递地址" prop="address">
            <el-input v-model="ruleForm.address" />
          </el-form-item> -->

          <!-- <hr />

          <el-form-item label="预存金额" prop="mobile">
            <el-input v-model="ruleForm.mobile" />
            <p>为了充分利用您的科研经费，减少反复报账带来的烦恼，想可以预存金额到账户，同时享受预存报外积分奖励。</p>
            <div class="flex">
              <div></div>
            </div>
          </el-form-item> -->
        </div>

        <div class="operate">
          <el-button
            class="submit-btn"
            type="primary"
            @click="submitForm(ruleFormRef)"
            :loading="_btnLoading"
          >
            立即提交
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, inject } from 'vue'
import * as _request from '@/api/login.js'
import { ElMessage } from 'element-plus'

const userInfo = inject('userInfo')
const userInfoChange = inject('userInfoChange')

/**
 * @description form表单相关
 */
const formSize = ref('default')
const ruleFormRef = ref()
const _btnLoading = ref(false)
const ruleForm = reactive({
  name: userInfo.value.username || '', // 姓名
  mobile: userInfo.value.mobile || '', // 电话
  email: userInfo.value.email || '', //
  unit_name: userInfo.value.unit_name || '', // 单位名称
  unit_num: userInfo.value.unit_num || '', // 单位税号
  // address: userInfo.value.address || '' // 地址
  invite_code: userInfo.value.invite_code || '', // 推荐码
})

const rules = reactive({
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  mobile: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
  email: [{ required: true, message: '请输入账户', trigger: 'blur' }]
  // unit_name: [{ required: true, message: '请输入联系电话', trigger: 'blur' }]
  // unit_num: [{ required: true, message: '请输入联系电话', trigger: 'blur' }]
  // address: [{ required: true, message: '请输入联系电话', trigger: 'blur' }]
})

/**
 * @description 表单提交
 */
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      _btnLoading.value = true
      const _params = ruleForm
      _request
        .ajax_user_edit(_params)
        .then((res) => {
          ElMessage({
            message: '修改成功',
            type: 'success',
            duration: 1000
          })
          getUserInfo()
          _btnLoading.value = false
        })
        .finally((err) => {
          _btnLoading.value = false
        })
    } else {
      _btnLoading.value = false
      console.log('error submit!', fields)
    }
  })
}

/**
 * @description 个人信息获取
 */
function getUserInfo() {
  const _params = {}
  _request.ajax_user_info(_params).then((res) => {
    // console.log('res', res.data)
    localStorage.setItem('userInfo', JSON.stringify(res.data))
    userInfoChange(res.data)
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.personal-information-block {
  background: #f4f4f4;
  padding-bottom: 60px;
  padding-top: 60px;
  overflow: hidden;

  .width-1200 {
    width: 1080px;
    margin: 0 auto;
  }

  .crumbs-block {
    margin: 0 auto 25px auto;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #666666;

    .el-icon {
      margin: 0 6px;
    }

    span {
      color: #cccccc;
    }
  }

  .form-block {
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 60px 60px 60px;
    box-sizing: border-box;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 0 0 30px 0;
      display: flex;
      align-items: center;

      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 20px;
        background: #0e6eb8;
        border-radius: 2px;
        margin-right: 12px;
      }
    }

    .form-card {
      width: 100%;
      background: #f2f8fc;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 20px;
    }

    h5 {
      font-size: 16px;
      font-weight: 500;
      color: #2a2a2a;
      line-height: 1;
      margin: 0 0 24px 0;
    }

    .inhibit-input-text {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      line-height: 32px;
      margin: 0;
    }

    .tip {
      font-size: 14px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 10px 0 0 0;
    }

    .el-input,
    .el-textarea {
      width: 620px;
    }

    .upload-block {
      flex-grow: 1;
      justify-content: space-between;

      .important-notice {
        width: 390px;
      }
    }
  }

  .important-notice {
    background: #fff5f5;
    border: 1px solid #ffd6d6;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 30px;
    margin-bottom: 40px;

    img {
      width: 18px;
      height: 18px;
      margin: 0 6px 0 0;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 14px;
      color: #666666;
      line-height: 18px;
      margin: 0;
    }
  }

  .operate {
    text-align: center;

    .submit-btn {
      width: 300px;
      height: 50px;
      background: #0e6eb8;
      border-radius: 25px;

      &:hover {
        background: #0099ff;
      }
    }
  }
}
</style>
