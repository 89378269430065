<template>
  <div class="header-block">
    <div class="flex">
      <!-- <div class="title">logo</div> -->
      <img class="logo" src="@/assets/images/logo.png" />

      <el-menu
        :default-active="_propsData.menuDefaultActive"
        mode="horizontal"
        :ellipsis="false"
      >
        <template v-for="item in _routerList" :key="item.path">
          <el-sub-menu v-if="item.children" :index="item.path">
            <template #title>{{ item.meta.title }}</template>
            <el-menu-item
              :index="childrenItem.path"
              :key="childrenItem.path"
              v-for="childrenItem in item.children"
              @click="routerLink(childrenItem.path)"
              >{{ childrenItem.meta.title }}</el-menu-item
            >
            <!-- <el-sub-menu index="2-4">
        <template #title>item four</template>
        <el-menu-item index="2-4-1">item one</el-menu-item>
        <el-menu-item index="2-4-2">item two</el-menu-item>
        <el-menu-item index="2-4-3">item three</el-menu-item>
      </el-sub-menu> -->
          </el-sub-menu>

          <el-menu-item
            v-else
            :index="item.path"
            @click="routerLink(item.path)"
            >{{ item.meta.title }}</el-menu-item
          >
        </template>
      </el-menu>

      <div class="flex operation">
        <el-dropdown
          v-if="userInfo && userInfo.username"
          @command="handleCommand"
        >
          <span class="user-info flex">
            <img
              class="head-portrait"
              src="@/assets/images/head_portrait.png"
            />
            <span>{{ userInfo.username }}&nbsp;</span>
            <el-icon><ArrowDown /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="personal_center"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item command="userinfo">个人信息</el-dropdown-item>
              <el-dropdown-item command="password">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <div v-else class="logout" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>

<script setup>
// defineEmits
import { ref, onMounted, reactive, defineProps, inject } from 'vue'
import router from '@/router/index.js'
import Cookies from 'js-cookie'
import * as _request from '@/api/login.js'
import * as _requestUser from '@/api/user.js'

/**
 * @description props参数
 */
const _propsData = defineProps({
  menuDefaultActive: {
    type: String,
    default: ''
  }
})

const loginDialogVisibleChange = inject('loginDialogVisibleChange')
const changePasswordDialogVisibleChange = inject(
  'changePasswordDialogVisibleChange'
)
const userInfo = inject('userInfo')
const userInfoChange = inject('userInfoChange')



/**
 * @description 菜单权限过滤 show为false的不展示
 */
const _routerListAll = router.options.routes.filter((item) => {
  return item.show
})
_routerListAll.map((item) => {
  if (item.children) {
    const _newChildren = []
    item.children.map((children) => {
      if (children.show) {
        _newChildren.push(children)
      }
    })
    item.children = _newChildren
  }
})
let _routerList = ref(_routerListAll)

/**
 * @description 路由跳转
 */
function routerLink(_target) {
  console.log(_target)
  router.push({
    path: _target,
    query: {}
  })
}

/**
 * @description 个人信息获取
 */
getUserInfo()
function getUserInfo() {
  const _params = {}
  _request.ajax_user_info(_params).then((res) => {
    localStorage.setItem('userInfo', JSON.stringify(res.data))
    userInfoChange(res.data)
  })
}

// /**
//  * @description 获取用户信息
//  */
// // const userInfo = ref('')
// // getUserCenter()
// function getUserCenter() {
//   const _params = {}
//   _requestUser.userCenter(_params).then((res) => {
//     console.log('res', res)
//     // pageTotal.value = parseFloat(res.data.total)
//     // console.log('pageTotal', pageTotal.value)
//     // userInfo.value = res.data[0]
//   })
// }

/**
 * @description 个人信息、修改密码点击事件
 */
const handleCommand = (command) => {
  // console.log(`click on item ${command}`)
  if (command == 'personal_center') {
    router.push({
      path: '/personal-center',
      query: {}
    })
  } else if (command == 'userinfo') {
    router.push({
      path: '/personal-information',
      query: {}
    })
  } else if (command == 'password') {
    changePasswordDialogVisibleChange()
  } else if (command == 'logout') {
    logout()
  }
}

/**
 * @description 登录
 */
function login() {
  loginDialogVisibleChange()
}

/**
 * @description 退出
 */
function logout() {
  const _params = {}
  _request.logout(_params).then((res) => {
    // console.log('res', res)
    // localStorage.clear()
    localStorage.removeItem('userInfo')
    sessionStorage.clear()
    // Cookies.get('cosmoplat-access-token')
    // Cookies.set("session", data.access_token);
    Cookies.remove('course')
    // location.reload()
    userInfoChange({})
    setTimeout(() => {
      router.push({
        path: '/',
        query: {}
      })
    }, 300)
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-block {
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.09);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  & > .flex {
    height: 100%;
    margin: 0 60px;
    justify-content: space-between;
    align-items: center;
  }

  // .title {
  //   font-size: 20px;
  //   font-weight: bold;
  //   color: #242859;
  // }

  .logo {
    height: 46px;
    background: #ffffff;
    border-radius: 10px;
  }

  .operation {
    align-items: center;

    .vertical-bar {
      color: rgba(219, 228, 243, 1);
      margin: 0 12px;
    }

    .user-info {
      margin-right: 26px;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #242859;
      cursor: pointer;

      &:hover {
        color: rgba(50, 112, 255, 1);
      }

      .head-portrait {
        width: 40px;
        margin-right: 15px;
      }
    }
  }

  .logout {
    width: 100px;
    height: 36px;
    background: #0e6eb8;
    border-radius: 18px;
    opacity: 1;
    line-height: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: #0099ff;
    }
  }
}

.dialog-userinfo-block {
  .userinfo {
    margin: 0 57px 27px 57px;

    .head-portrait {
      display: block;
      width: 64px;
      height: 64px;
      margin-right: 33px;
    }

    p {
      margin: 0 0 12px 0;
      font-size: 12px;
      font-weight: 400;
      color: #242859;
    }
  }
}
</style>
