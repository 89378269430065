<template>
  <div class="join-activities-block">
    <div class="width-1200 crumbs-block flex">
      <!-- <el-link href="/course" target="_blank">讲师招募</el-link>
      <el-icon><ArrowRight /></el-icon>
      <span>讲师招募</span> -->
    </div>

    <div class="width-1200 background-ffffff form-block">
      <div class="title">联系信息</div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
      >
        <div class="form-card">
          <h5>个人信息</h5>
          <el-form-item label="姓名" prop="contact_name">
            <el-input v-model="ruleForm.contact_name" />
          </el-form-item>
          <el-form-item label="电话" prop="teacher_mobile">
            <el-input v-model="ruleForm.teacher_mobile" />
          </el-form-item>
          <el-form-item label="单位名称" prop="unit_name">
            <el-input v-model="ruleForm.unit_name" />
          </el-form-item>
          <el-form-item label="邮箱" prop="teacher_email">
            <el-input v-model="ruleForm.teacher_email" />
          </el-form-item>
        </div>

        <div class="form-card">
          <h5>其他</h5>

          <el-form-item label="备注">
            <el-input
              v-model="ruleForm.remarks"
              placeholder="请在此输入您的特殊需求，中英文均可"
              type="textarea"
            />
            <p class="tip">您想备注的信息可放在此处。</p>
          </el-form-item>
        </div>

        <div class="operate">
          <el-button
            class="submit-btn"
            type="primary"
            @click="submitForm(ruleFormRef)"
            :loading="_btnLoading"
          >
            信息确认无误，立即提交
          </el-button>
        </div>
      </el-form>
    </div>

    <!-- 图片查看 -->
    <el-dialog v-model="dialogVisible">
      <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, inject } from 'vue'
import * as _request from '@/api/lecturers.js'
import { ElMessage } from 'element-plus'
import router from '@/router/index.js'

const userInfo = inject('userInfo')
const userInfoChange = inject('userInfoChange')
const loginDialogVisibleChange = inject('loginDialogVisibleChange')

/**
 * @description form表单相关
 */
const formSize = ref('default')
const ruleFormRef = ref()
const _btnLoading = ref(false)
const ruleForm = reactive({
  contact_name: userInfo.value.username, // 姓名
  teacher_mobile: userInfo.value.mobile, // 电话
  // invited_code: '', // 推荐码
  remarks: '', // 备注
  // ID_card: '', // 身份证
  // weixin: '', // 微信
  teacher_email: '', //
  unit_name: '' // 单位名称
})

const rules = reactive({
  contact_name: [
    { required: true, message: '请输入姓名', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  teacher_mobile: [
    { required: true, message: '请输入联系电话', trigger: 'blur' }
  ],
  teacher_email: [
    { required: true, message: '请输入联系邮箱', trigger: 'blur' }
  ],
  unit_name: [{ required: true, message: '请输入单位名称', trigger: 'blur' }]
})

/**
 * @description 信息获取
 */
if (!userInfo.value || !userInfo.value.email) {
  loginDialogVisibleChange()
} else {
  getDataInfo()
}

function getDataInfo() {
  const _params = {}
  _request.teacher_ajax_info(_params).then((res) => {
    if (res.error_code == 0) {
      const _data = res.data
      ruleForm.contact_name = _data.contact_name
        ? _data.contact_name
        : userInfo.value.username
      ruleForm.teacher_mobile = _data.teacher_mobile
        ? _data.teacher_mobile
        : userInfo.value.mobile
      ruleForm.teacher_email = _data.teacher_email ? _data.teacher_email : ''
      ruleForm.unit_name = _data.unit_name ? _data.unit_name : ''
      ruleForm.remarks = _data.remarks ? _data.remarks : ''
    }
  })
}

/**
 * @description 表单提交
 */
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      _btnLoading.value = true
      const _params = ruleForm
      _request
        .teacher_ajax_take(_params)
        .then((res) => {
          if (res.error_code == 0) {
            ElMessage({
              message: res.error_msg,
              type: 'success',
              duration: 1000
            })
          }
          _btnLoading.value = false
        })
        .finally((err) => {
          _btnLoading.value = false
          console.log('catch: ', err)
        })
    } else {
      _btnLoading.value = false
      console.log('error submit!', fields)
    }
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.join-activities-block {
  background: #f4f4f4;
  padding-bottom: 60px;
  padding-top: 0;
  overflow: hidden;

  .width-1200 {
    width: 1080px;
    margin: 0 auto;
  }

  .crumbs-block {
    margin: 25px auto 25px auto;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #666666;

    .el-icon {
      margin: 0 6px;
    }

    span {
      color: #cccccc;
    }
  }

  .form-block {
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 60px 60px 60px;
    box-sizing: border-box;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 0 0 30px 0;
      display: flex;
      align-items: center;

      &::before {
        content: ' ';
        display: block;
        width: 4px;
        height: 20px;
        background: #0e6eb8;
        border-radius: 2px;
        margin-right: 12px;
      }
    }

    .form-card {
      width: 100%;
      background: #f2f8fc;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 20px;
    }

    h5 {
      font-size: 16px;
      font-weight: 500;
      color: #2a2a2a;
      line-height: 1;
      margin: 0 0 24px 0;
    }

    .inhibit-input-text {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      line-height: 32px;
      margin: 0;
    }

    .tip {
      font-size: 14px;
      font-weight: 500;
      color: #0e6eb8;
      line-height: 1;
      margin: 10px 0 0 0;
    }

    .el-input,
    .el-textarea {
      width: 620px;
    }

    .upload-block {
      flex-grow: 1;
      justify-content: space-between;

      .important-notice {
        flex-shrink: 0;
        width: 390px;
      }
    }
  }

  .important-notice {
    background: #fff5f5;
    border: 1px solid #ffd6d6;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 30px;
    margin-bottom: 40px;

    img {
      width: 18px;
      height: 18px;
      margin: 0 6px 0 0;
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
      margin: 0 0 10px 0;
    }

    p {
      font-size: 14px;
      color: #666666;
      line-height: 18px;
      margin: 0;
    }
  }

  .operate {
    text-align: center;

    .submit-btn {
      width: 300px;
      height: 50px;
      background: #0e6eb8;
      border-radius: 25px;

      &:hover {
        background: #0099ff;
      }
    }
  }
}
</style>
