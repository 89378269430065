<template>
  <div class="purchased-data-block">
    <el-tabs v-model="_activeName" @tab-change="handleClick">
      <el-tab-pane label="已购课程" name="purchased">
        <!-- 卡片样式的商品列表 -->
        <CardList
          v-if="_activeName == 'purchased' && _orderList.length > 0"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
      <el-tab-pane label="免费课程" name="free">
        <!-- 卡片样式的商品列表 -->
        <CardList
          v-if="_activeName == 'free' && _orderList.length > 0"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
      <el-tab-pane label="会员专享" name="vip">
        <!-- 卡片样式的商品列表 -->
        <CardList
          v-if="_activeName == 'vip' && _orderList.length > 0"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
    </el-tabs>

    <div class="pagination-block">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :total="pageTotal"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import * as _request from '@/api/user.js'
import * as _requestActivities from '@/api/activities.js'
import router from '@/router/index.js'
import CardList from './card_list_course.vue'

/**
 * @description tabs选项卡
 */
const _activeName = ref('purchased')
const handleClick = (tab, event) => {
  if (_activeName.value == 'purchased') {
    // 订单状态,1，待审核；2，驳回；3，已完成；4，废弃
    getOrderList(3)
  } else if (_activeName.value == 'free') {
    getPurchasedList(0)
  } else if (_activeName.value == 'vip') {
    getPurchasedList(1)
  }
}
/**
 * @description 分页数据获取
 */
const _orderList = ref([])
const currentPage = ref(1)
const pageSize = ref(10)
const pageTotal = ref(0)
getOrderList(3)
function getOrderList(_status) {
  const _params = {
    page: currentPage.value,
    limit: pageSize.value,
    status: _status
  }
  _orderList.value = []
  _request.ajax_course_order(_params).then((res) => {
    // console.log('res', res)
    pageTotal.value = res.data.count || 0
    _orderList.value = res.data.list || []
  })
}

function getPurchasedList(_status) {
  const _params = {
    page: currentPage.value,
    limit: pageSize.value,
    level: _status
  }
  _orderList.value = []
  _requestActivities.ajax_course_list(_params).then((res) => {
    // console.log('res', res)
    pageTotal.value = res.data.count || 0
    _orderList.value = res.data.list || []
    console.log('_orderList.value', _orderList.value, res.data.list)
  })
}

/**
 * @description 分页切换
 * @param {*} val
 */
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
  currentPage.value = val
  if (_activeName.value == 'purchased') {
    // 订单状态,1，待审核；2，驳回；3，已完成；4，废弃
    getOrderList(3)
  } else if (_activeName.value == 'free') {
    getPurchasedList(0)
  } else if (_activeName.value == 'vip') {
    getPurchasedList(1)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.purchased-data-block {
  :deep(.el-tabs) {
    .el-tabs__nav {
      margin-left: 40px;
    }

    .el-tabs__item {
      padding: 0 35px;
    }
  }

  .pagination-block {
    border-top: 1px solid #e6e6e6;
    padding-top: 30px;
  }
}
</style>
