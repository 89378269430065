<template>
  <el-dialog
    v-model="dialogVisible"
    title="重要提醒"
    width="900px"
    top="5vh"
    class="dialog-block"
    :before-close="handleClose"
  >
    <div class="login-form-block">
      <h3>《光锥时空论文服务保密协议》</h3>
      <p>
        用户在使用北京光锥时空科技有限公司所属的光锥时空™服务之前，请您务必审慎阅读、充分理解本协议的各项条款内容。您在光锥时空™服务网站www.shikongxuexi.com上勾选本协议即视为您已阅读并同意本协议的全部内容。 
      </p>
      <p>
        鉴于您与光锥时空™合作中双方需要交换具有保密需求的信息，双方同意妥善保护彼此交换的信息与资料。双方就保密义务达成如下协议： 
      </p>
      <h5>1、需要保密的信息</h5>
      <p>
        本协议中所涉及的保密信息是指双方因合作需要而交换的所有注明保密或要求保密的资料，包括但不限于:服务文件、订单信息、文字、数据、图表、参考资料及个人信息等双方认可确认的资料。 
      </p>

      <h5>2、保密责任</h5>
      <p>
        1） 双方必须采取有效的安全措施和操作规程防止对方的保密信息不被泄露。 
      </p>
      <p>2） 未经对方允许，任意一方都无权复制、拷贝以及转移保密信息。</p>
      <p>
        3）
        双方应各自妥善保管自身的帐号、密码、验证码。因自身保管不善可能导致的遭受盗号或密码失窃，责任由自身承担。 
      </p>
      <p>
        4）
        如根据中国法律及法规的强制性规定，一方必须披露保密信息的，可根据相关法律、法规的要求披露相关保密信息。 
      </p>
      <h5>3、使用限制</h5>
      <p>
        1）非对方允许，任意一方不得透露涉及商业使用权、专利权、复制权、商标、技术机密、商业机密或其他归对方专有的权利。 
      </p>
      <p>
        2）非对方许可或保密信息已公开发表，任意一方不得将合作的事宜和保密内容，透露给对方的商业竞争对手或其他威胁到对方利益的单位或个人等第三方，若因一方原因，导致对方保密信息泄漏第三方，威胁其利益，由泄漏方承担相应的法律责任。 
      </p>
      <p>
        3）光锥时空™将保密信息的接触范围严格限制在因本合作目的需接触保密信息的雇员、代理人范围内，光锥时空™有义务约束上述人员履行保密义务。 
      </p>
      <p>
        4）双方合作订单的文档资料，光锥时空™的保留期为一年。到期后，光锥时空™会自行销毁或向对方返还其占有或控制的全部保密信息以及包含或体现了保密信息的全部文件、其它材料并连同全部副本。若合作方提前向光锥时空™提出销毁稿件资料要求，应出具书面签字文件；删除资料后造成合作方售后服务权益缺失，由合作方全权承担责任。 
      </p>
      <h5>4、无需承担责任的事项</h5>
      <p>双方对下述信息无须承担保密义务：</p>
      <p>1） 在不承担保密责任的情况下获取的信息；</p>
      <p>2） 独立开发的信息；</p>
      <p>3） 在不违反保密义务的情况下，从对方以外的渠道获取的信息；</p>
      <p>4） 不是由于对方违反本协议而成为公开信息的信息；</p>
      <p>5） 对方向无须承担保密责任的第三方泄漏的信息。</p>
      <p>
        双方可以在其业务活动中使用残留在其曾经接触过保密信息的雇员的记忆中的并已成为其个人知识或经验的，与保密信息相关的构想、概念或技巧，但是就保密信息而言，双方仍然应当按本协议的规定履行保密义务。 
      </p>
      <h5>5、争议解决和适用法律</h5>
      <p>1） 本协议受中华人民共和国法律管辖并按中华人民共和国法律解释。</p>
      <p>2） 对本协议的任何修改须经双方书面签署方为有效。</p>
      <p>
        3）
        执行本协议发生争议时，双方应首先通过友好协商的方式解决；若协商不成，任何一方可向当地仲裁委员会提起仲裁申请予以解决。仲裁不成的，可以向当地法院提起诉讼。 
      </p>
      <h5>6、协议时间</h5>
      <p>
        本协议自您通过光锥时空™服务网站www.shikongxuexi.com达成服务合作起正式生效。 
      </p>

      <h4>2023年1月31日起执行</h4>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="login-submit" type="primary" @click="confirm">
          确 认
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, inject } from 'vue'

const dialogVisible = ref(true)
const YinSiTiaoKuanDialogVisibleChange = inject(
  'YinSiTiaoKuanDialogVisibleChange'
)

const handleClose = (done) => {
  YinSiTiaoKuanDialogVisibleChange()
}

const confirm = () => {
  YinSiTiaoKuanDialogVisibleChange()
}
</script>
<style lang="scss" scoped>
.dialog-block {
  .login-form-block {
    box-sizing: border-box;
    /* padding: 40px 40px 0 40px; */

    h3 {
      text-align: center;
      margin-top: 0;
      font-size: 26px;
    }

    h5 {
      font-size: 16px;
    }

    h4 {
      font-size: 16px;
      text-align: center;
    }

    p {
      font-size: 16px;
      text-indent: 30px;
    }
  }

  .forget-register {
    box-sizing: border-box;
    padding: 0 20px 10px 20px;
    margin-top: 18px;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  .forget,
  .register {
    cursor: pointer;
  }

  .register {
    color: #0e6eb8;
  }
}
</style>