import request from "@/util/request";

/**
 * @description 导师报名
 */
 function teacher_ajax_take(params) {
  return request({
    url: "/api/m/teacher/ajax_take",
    method: "get",
    params
  });
}


/**
 * @description 导师报名信息查询
 */
function teacher_ajax_info(params) {
  return request({
    url: "/api/m/teacher/ajax_info",
    method: "get",
    params
  });
}



export { 
  teacher_ajax_take,
  teacher_ajax_info,
};
