import request from "@/util/request";

/**
 * @description 获取活动列表
 */
function ajax_activity_list(params) {
  return request({
    url: "/api/m/activity/ajax_activity_list",
    method: "get",
    params,
  });
}

/**
 * @description 获取活动信息
 */
function ajax_activity_info(params) {
  return request({
    url: "/api/m/activity/ajax_info",
    method: "get",
    params,
  });
}

/**
 * @description 参加活动
 */
function ajax_activity_take(data) {
  return request({
    url: "/api/m/activity/ajax_take",
    method: "post",
    data,
  });
}

/**
 * @description 获取课程列表
 */
function ajax_course_list(params) {
  return request({
    url: "/api/m/courser/ajax_course_list",
    method: "get",
    params,
  });
}

/**
 * @description 获取课程信息
 */
function ajax_course_info(params) {
  return request({
    url: "/api/m/course/ajax_info",
    method: "get",
    params,
  });
}

/**
 * @description 购买课程
 */
function ajax_course_take(data) {
  return request({
    url: "/api/m/course/ajax_take",
    method: "post",
    data,
  });
}

/**
 * @description 获取banner
 */
function ajax_banner_list(params) {
  return request({
    url: "/api/m/banner/ajax_banner_list",
    method: "get",
    params,
  });
}

/**
 * @description 校验活动状态是否正常，就是判断是否有权限播放视频、音频、文档
 */
function ajax_is_take_activity(params) {
  return request({
    url: "/api/m/activity/ajax_is_take_activity",
    method: "get",
    params,
  });
}

/**
 * @description 校验课程状态是否正常，就是判断是否有权限播放视频、音频、文档
 */
function ajax_is_take_course(params) {
  return request({
    url: "/api/m/mcourse/ajax_is_take_course",
    method: "get",
    params,
  });
}

export {
  ajax_activity_list,
  ajax_activity_info,
  ajax_activity_take,
  ajax_course_list,
  ajax_course_info,
  ajax_course_take,
  ajax_banner_list,
  ajax_is_take_activity,
  ajax_is_take_course
};
