import request from "@/util/request";

/**
 * @description 活动订单列表
 */
function ajax_activity_order(params) {
  return request({
    url: "/api/m/order/ajax_activity_order",
    method: "get",
    params,
  });
}

/**
 * @description 课程订单列表
 */
function ajax_course_order(params) {
  return request({
    url: "/api/m/order/ajax_course_order",
    method: "get",
    params,
  });
}

/**
 * @description 课程付款凭证上传
 */
function ajax_course_pay(data) {
  return request({
    url: "/api/m/order/ajax_course_pay",
    method: "post",
    data,
  });
}

/**
 * @description 活动付款凭证上传
 */
function ajax_activity_pay(data) {
  return request({
    url: "/api/m/order/ajax_activity_pay",
    method: "post",
    data,
  });
}

export {
  ajax_activity_order,
  ajax_course_order,
  ajax_course_pay,
  ajax_activity_pay
};
