<template>
  <div class="commodity-info-block">
    <!-- <img class="banner" src="@/assets/images/banner2.png" /> -->

    <div class="width-1400 background-ffffff overflow-hidden">
      <div class="width-1200">
        <div class="commodity-info flex">
          <div class="thumbnail">
            <img
              :src="
                _dataInfo.course_cover ? _dataInfo.course_cover : _defaultImg
              "
            />
          </div>
          <div class="price">
            <h2>{{ _dataInfo.course_name }}</h2>
            <!-- <p>
              <span class="label">时间：</span>{{ _dataInfo.activity_date }}
            </p>
            <p>
              <span class="label">地点：</span>{{ _dataInfo.activity_area }}
            </p> -->
            <p class="flex">
              <span class="label">简述：</span>{{ _dataInfo.course_des }}
            </p>
            <div class="flex flex-align-items">
              <span class="label">可选服务：</span>
              <div class="service-select flex">
                <!-- !item.have_file &&  -->
                <div
                  @click="serviceClick(_dataInfo.price_info)"
                  v-if="_dataInfo.price_info && _dataInfo.price_info.level == 2"
                  :class="{
                    'service-item': true,
                    active: _activeServiceId == _dataInfo.price_info.id
                  }"
                >
                  {{ _dataInfo.price_info.level_name }}
                  <img
                    class="icon-selected"
                    src="@/assets/images/selected.png"
                  />
                </div>
                <!-- <template
                  v-for="(item, index) in _dataInfo.price_info"
                  :key="index"
                >
                  <div
                    @click="serviceClick(item)"
                    v-if="item.level == 2"
                    :class="{
                      'service-item': true,
                      active: _activeServiceId == item.id
                    }"
                  >
                    {{ item.level_name }}
                    <img
                      class="icon-selected"
                      src="@/assets/images/selected.png"
                    />
                  </div>
                </template> -->
              </div>
            </div>
            <p style="margin-top: 10px">
              <span class="label">价格:</span>
              <span class="number"> ¥{{ _activeServicePrice || 0 }} </span>
            </p>

            <div class="flex margin-top-20">
              <!-- <a
                href="http://wpa.qq.com/msgrd?v=3&uin=1804232143&site=qq&menu=yes"
                target="_blank"
                class="talk-btn"
                >QQ咨询详情</a
              > -->
              <el-popover placement="right" trigger="hover">
                <template #reference>
                  <div class="talk-btn">微信咨询详情</div>
                </template>

                <div>
                  <p style="margin: 0 0 0 10px">扫码咨询刘老师</p>
                  <img
                    style="width: 120px"
                    src="@/assets/images/teacher-liu.jpeg"
                  />
                </div>
              </el-popover>
              <div
                v-if="_dataInfo.course_status == 1"
                class="purchase-btn"
                @click="toBuy"
              >
                购买报名
              </div>
            </div>
          </div>
        </div>

        <div class="commodity-info-tabs-block">
          <el-tabs v-model="_activeName">
            <el-tab-pane label="介绍" name="introduce">
              <Introduce
                :data="_dataInfo"
                v-if="_activeName == 'introduce' && _dataInfo.course_name"
              />
            </el-tab-pane>
            <el-tab-pane label="目录" name="directory">
              <div class="directory-block">
                <!-- <p
                  v-if="
                    _activeFileList[1].length == 0 &&
                    _activeFileList[2].length == 0 &&
                    _activeFileList[3].length == 0
                  "
                  style="text-align: center;"
                >
                  购买课程后将在此展示回放视频、文档资料等。
                </p> -->
                <DirectoryList
                  v-if="_activeName == 'directory' && _activeFileList"
                  :data="_activeFileList[1]"
                  type="document"
                  category="course"
                  :categoryId="router.currentRoute.value.query.id"
                />
                <DirectoryList
                  v-if="_activeName == 'directory' && _activeFileList"
                  :data="_activeFileList[2]"
                  type="video"
                  category="course"
                  :categoryId="router.currentRoute.value.query.id"
                />
                <DirectoryList
                  v-if="_activeName == 'directory' && _activeFileList"
                  :data="_activeFileList[3]"
                  type="audio"
                  category="course"
                  :categoryId="router.currentRoute.value.query.id"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import * as _request from '@/api/activities.js'
import router from '@/router/index.js'
import Introduce from './components/introduce.vue'
// import Catalogue from './components/catalogue.vue'
import DirectoryList from '@/components/DirectoryList.vue'
import { ElMessage } from 'element-plus'

const _defaultImg = require('@/assets/images/banner.png') // 这里需要换成绝对路径
const _activeName = ref('introduce')

/**
 * @description 已选中服务id，服务目前只能单选
 */
const _activeServiceId = ref('')
const _activeServiceObj = ref({})
const _activeServicePrice = ref('')
const _activeFileList = ref([]) // 已选中服务下面的课程
function serviceClick(_item) {
  _activeServiceObj.value = _item
  _activeServiceId.value = _item.id
  _activeServicePrice.value = _item.price
  _activeFileList.value = _item.file_list
}

/**
 * @description 信息获取
 */
const _dataInfo = ref({})
getDataInfo()
function getDataInfo() {
  const _params = {
    course_id: router.currentRoute.value.query.id
  }
  _request.ajax_course_info(_params).then((res) => {
    // console.log('res', res.data)
    // 默认选中第一个付费服务
    if (res.data.price_info) {
      _activeServiceObj.value = res.data.price_info
      _activeServiceId.value = res.data.price_info.id
      _activeServicePrice.value = res.data.price_info.price
      _activeFileList.value = res.data.price_info.file_list
      // for (let i = 0; i < res.data.price_info.length; i++) {
      //   if (res.data.price_info[i].level == 2) {
      //     _activeServiceId.value = res.data.price_info[i].id
      //     _activeServicePrice.value = res.data.price_info[i].price
      //     _activeFileList.value = res.data.price_info[i].file_list

      //     break
      //   }
      // }
    }
    _dataInfo.value = res.data
  })
}

/**
 * @description 跳转提交表单
 */
function toBuy() {
  if (!_activeServiceId.value) {
    ElMessage({
      message: '请先选择服务',
      type: 'error',
      duration: 1000
    })
    return
  }
  if (_activeServiceObj.value.have_file) {
    ElMessage({
      message: '无需重复购买',
      type: 'error',
      duration: 1000
    })
    return
  }
  router.push({
    path: '/join-course',
    query: {
      id: router.currentRoute.value.query.id,
      service: _activeServiceId.value
    }
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.commodity-info-block {
  background: #f4f4f4;

  .banner {
    width: 100%;
  }

  .width-1200 {
    margin-top: 60px;
    margin-bottom: 80px;
  }

  .commodity-info {
    .thumbnail {
      width: 480px;
      height: 340px; // 360px;
      background: #e7f4fa;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 40px;
      flex: none;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .price {
      flex-grow: 1;

      h2 {
        font-size: 20px;
        font-weight: bold;
        color: #292929;
        line-height: 1;
        padding: 20px 0 25px 0;
        border-bottom: 1px solid #e6e6e6;
        margin: 0;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin: 15px 0;
      }

      .label {
        display: inline-block;
        width: 80px;
        font-size: 14px;
        font-weight: 500;
        color: #7f7f7f;
        flex: none; /** 使项目不能被压缩或放大*/
      }

      .service-select {
        flex-wrap: wrap;
        .service-item {
          min-width: 80px;
          height: 36px;
          background: #ffffff;
          border: 1px solid #999999;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          line-height: 36px;
          text-align: center;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          position: relative;
          padding: 0 10px;

          .icon-selected {
            display: none;
            position: absolute;
            bottom: 0;
            right: 0;
          }

          &:hover {
            border: 1px solid #0e6eb8;
            color: #0e6eb8;
          }

          &.active {
            border: 1px solid #0e6eb8;
            color: #0e6eb8;
            .icon-selected {
              display: block;
            }
          }
        }
      }

      .number {
        font-size: 24px;
        font-weight: 500;
        color: #ff6d39;
      }

      .talk-btn {
        width: 120px;
        height: 40px;
        background: #0e6eb8;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        margin-right: 20px;
        cursor: pointer;

        &:hover {
          background: #0099ff;
        }
      }

      .purchase-btn {
        width: 120px;
        height: 40px;
        background: #ff6d39;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: #ef8d6a;
        }
      }
    }
  }

  .commodity-info-tabs-block {
    margin-top: 45px;

    :deep(.el-tabs) {
      .el-tabs__nav {
        margin-left: 50px;
      }

      .el-tabs__item {
        padding: 0 35px;
        font-size: 18px;
      }
    }

    .directory-block {
      width: 1200px;
      background: #f5f5f5;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 40px 40px 30px 40px;
    }
  }
}
</style>
