<template>
  <div class="purchased-data-block">
    <el-tabs v-model="_activeName" class="demo-tabs" @tab-change="handleClick">
      <el-tab-pane label="线上会议" name="purchased">
        <!-- 卡片样式的商品列表 -->
        <CardList
          v-if="_activeName == 'purchased' && _orderList.length > 0"
          :data="_orderList"
          :colSpanCount="8"
        />
      </el-tab-pane>
    </el-tabs>

    <div class="pagination-block">
      <el-pagination
        background
        layout="total, prev, pager, next"
        :total="pageTotal"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import * as _request from '@/api/user.js'
import router from '@/router/index.js'
import CardList from './card_list_activity.vue'

/**
 * @description tabs选项卡
 */
const _activeName = ref('purchased')
const handleClick = (tab, event) => {
  console.log(tab, event)
  getOrderList(3)
}

/**
 * @description 分页数据获取
 */
const _orderList = ref([])
const currentPage = ref(1)
const pageSize = ref(10)
const pageTotal = ref(0)
getOrderList(3)
function getOrderList(_status) {
  const _params = {
    page: currentPage.value,
    limit: pageSize.value,
    status: _status || ''
  }
  _request.ajax_activity_order(_params).then((res) => {
    // console.log('res', res)
    pageTotal.value = res.data.count || 0
    _orderList.value = res.data.list || []
  })
}

/**
 * @description 分页切换
 * @param {*} val
 */
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
  currentPage.value = val
  getOrderList(3)
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.purchased-data-block {
  :deep(.el-tabs) {
    .el-tabs__nav {
      margin-left: 40px;
    }

    .el-tabs__item {
      padding: 0 35px;
    }
  }

  .pagination-block {
    border-top: 1px solid #e6e6e6;
    padding-top: 30px;
  }
}
</style>
